import axios from "@/axios";

export async function getCaseEntities(caseId) {
  const url = `case/${caseId}/entity`;
  const {data} = await axios.get(url);
  return data
}

export async function getCaseEntityChecks(caseId, entityId) {
    const url = `case/${caseId}/case_entity/${entityId}/check`;
    const {data} = await axios.get(url);
    return data
  }