import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.snow.css";
import AddCheck from "@/components/add-check";
import Menu from "@/components/dropdown";
import axios from "@/axios";
import ProfileInfoCard from "@/components/profile-info-card";
import modalContent from "@/components/modal-content";
import Dropdown from "@/components/dropdown";
import ProfileInfo from "@/components/profile-info";
import tabs from "@/components/dashboard-components/tabs";
import Card from "@shared/sc-journey/card";
import CaseCreationFlow from "@shared/case-creation/CaseCreationFlow";
// import AddEntityPanel from "@shared/saas-components/add-entity-panel";
import { EntityToolBarContainer } from "@shared/case-entities";
import cardShort from "@shared/sc-journey/card-short";
import nonReferenceCardShort from "@shared/sc-journey/non-reference-card-short";
import Icons from "@/components/icons-list";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@shared/assets/css/multiselect.scss";
import Input from "@/components/input";
import Button from "@/components/button/index.vue";
import CloseIcon from "@/assets/icons/email-admin/icon-crossbt.svg";
import SendIcon from "@/assets/icons/profile-view/send.svg";
import SendLater from "@/assets/icons/profile-view/sendlater.svg";
import ArrowDown from "@/assets/icons/profile-view/arrow-blue.svg";
import DropDownArrow from "@/assets/icons/profile-view/arrow-white.svg";
import FromIcon from "@/assets/icons/profile-view/send-mail.svg";
import BoldIcon from "@/assets/icons/profile-view/bold-icon.svg";
import ItalicIcon from "@/assets/icons/profile-view/italic-icon.svg";
import UnderlineIcon from "@/assets/icons/profile-view/underline-icon.svg";
import StrikeThroughIcon from "@/assets/icons/profile-view/strikethrough-icon.svg";
import CollapseIcon from "@/assets/icons/profile-view/collapse.svg";
const checklist = () => import("@/components/dashboard-components/checklist");
import CallLogModal from "@/components/sc-journey/modals/call-log.vue";
import TaskModal from "@/components/sc-journey/modals/task.vue";
import CompanyModal from "@/components/sc-journey/modals/company.vue";
import RefereeModal from "@/components/sc-journey/modals/referee.vue";
import { mapGetters, mapActions } from "vuex";
import FormBuilder from "@shared/components/form-builder";
import Empty from "@shared/empty";
import Timeline from "@shared/ChecksTimeline";
import DocumentUpload from "@shared/document-upload";
import tenantFormUi from "@shared/referee-form";
import { format } from "date-fns";
import Loader from "@/components/loader";
import refreeSvg from "@/assets/svg/refree.svg";
import candidateSvg from "@/assets/svg/candidate.svg";
import analystSvg from "@/assets/svg/analyst.svg";
import PersonIcon from "@shared/assets/icons/entity/person.svg";
import CompanyIcon from "@shared/assets/icons/entity/company.svg";
import pdfViewModal from "@shared/components/pdf-view/pdf-view-modal";
import MailModal from "@shared/sc-journey/modals/mail.vue";
import NoteModal from "@shared/sc-journey/modals/note.vue";
import DvDropdown from "@/components/dropdown-base";
import HooyuCheckResultsViewer from "@shared/components/integrated-checks/hooyu.vue";
import TransunionCheckResultsViewer from "@shared/components/integrated-checks/transunion.vue";
import DBSCheckResultsViewer from "@shared/components/integrated-checks/dbs.vue";
import GDCCheck from "@shared/components/integrated-checks/idVerification.vue";
import GDCBusiness from "@shared/components/integrated-checks/business-verification";
import { getRefreeFormFields } from "@shared/utils/functions";
const EmailNote = () => import("./components/email-note");
const EmailAttachments = () => import("./components/email-attachments");
const caseFilesManager = () => import("./components/case-files-manager");
import CustomizedForms from "./components/CustomizedForms.vue";
const FilePreview = () => import("@shared/components/file-preview");
const DeclineNote = () => import("@/components/decline-note");
const WorkAllocation = () => import("./components/work-allocation");
const ModalConfirm = () => import("@shared/modal-confirm");
const MoreOptions = () => import("./components/moreoptions");
const fileManager = () => import("@shared/dashboard-components/fileManager");
const searchQueries = () => import("@shared/components/searchedQueries");
const lexisBridger  = () => import("@shared/tools/lexis-bridger")
const companyIdentifier  = () => import("@shared/tools/company-identifier")
const SearchEngineTool = () => import("@shared/tools/search-engine");
import { isEmpty } from "lodash";
import { checkPermission } from "@shared/utils/functions";
import { EventBus } from "@/main.js";
import { DateTime } from "luxon";
import analystComment from "@shared/components/analystComment/analystComment.vue";
import { appList } from "@shared/components/form-builder/utils/index.js";
import { getCaseEntities } from './services.js';
import tools from "@shared/tools/mixins/tools"
import toolsHelper from "@shared/tools/mixins/toolsHelper";
import { getrelationAttributes, getIconsData } from "@shared/light-ray/services";
import eddTypeDropdown from "@shared/components/dropdown-base";
import FileField from "../../components/input-form-builder/components/form-components/file_upload.vue";
import { EDD_CHECK_ID } from "@shared/utils/constants";

import {
    formatSections,
    formatDataForFormPreview,
    mapEmails,
    getErrorMessage,
    checkNeedEntitiesUpdate,
} from "@shared/case-entities/utils";


// import { FormatDate } from "@/plugins/functions";
export default {
    mixins: [tools, toolsHelper],
    components: {
        "lexis-bridger-tool": lexisBridger,
        "company-name": companyIdentifier,
        "search-engine-tool": SearchEngineTool,
        searchQueries,
        CaseCreationFlow,
        // AddEntityPanel,
        EntityToolBarContainer,
        // Editor,
        DvDropdown,
        Empty,
        Loader,
        FormBuilder,
        quillEditor,
        AddCheck,
        "menu-list": Menu,
        "profile-info": ProfileInfo,
        "profile-info-card": ProfileInfoCard,
        "icons-list": Icons,
        modalContent,
        "neo-input": Input,
        "neo-button": Button,
        "vue-multiselect": Multiselect,
        Card,
        cardShort,
        nonReferenceCardShort,
        UnderlineIcon,
        tabs,
        CollapseIcon,
        StrikeThroughIcon,
        Dropdown,
        DropDownArrow,
        ItalicIcon,
        BoldIcon,
        FromIcon,
        ArrowDown,
        SendIcon,
        SendLater,
        CloseIcon,
        Timeline,
        checklist,
        CallLogModal,
        TaskModal,
        CompanyModal,
        RefereeModal,
        tenantFormUi,
        DocumentUpload,
        refreeSvg,
        analystSvg,
        candidateSvg,
        HooyuCheckResultsViewer,
        TransunionCheckResultsViewer,
        DBSCheckResultsViewer,
        GDCCheck,
        GDCBusiness,
        pdfViewModal,
        EmailNote,
        EmailAttachments,
        caseFilesManager,
        MailModal,
        NoteModal,
        FilePreview,
        DeclineNote,
        WorkAllocation,
        ModalConfirm,
        MoreOptions,
        fileManager,
        analystComment,
        CustomizedForms,
        eddTypeDropdown,
        FileField,
    },
    title() {
        return `${this.$route.params.caseid}`;
    },
    data() {
        return {
            isshowactivity: false,
            queriesResultsCount: [],
            toolData: [],
            checkSearched: false,
            entityType: "Case",
            entityList: [],
            caseEntitiesData: {},
            customizedFormData: {},
            customizedFormLoaders: {
                loading: false,
                creating: false,
                updating: false,
            },
            loaders: {
                entities: false,
            },
            currentEntityCollectInfo: null,
            dataCollectionSequences: {},
            dcrUsers: {},
            dcrSequencesResponse: null,
            additionalForms: [],
            isCustomizedFormsOpened: false,
            appList,
            hideHeader: false,
            additionFormLoader: false,
            environment: "",
            actionType: "",
            isResend: false,
            isdisabledClicked: false,
            isSendDisabled: false,
            isSearchModal: false,
            commentDetails: [],
            sendLink: true,
            clientEmails: [],
            form_user_list: [],
            additional_form_key: 2,
            selected_form_user: null,
            selected_dbs_form: null,
            dbs_form_list: [],
            dropdown_loaders: {
                user: false,
                form: false,
            },
            isUploading: false,
            uploadingMsg: false,
            filesList: [],
            component_type: "",
            formPreviewData: null,
            attachedFiles: [],
            case_id: "",
            svg: null,
            attachments: {
                mail_attachments: 0,
                notes_attachments: 0,
            },
            content: "",
            //  = undefined;: undefined,
            dummystatus: "none",
            // uuid: uuid.v4(),
            sendOptions: false,
            value: null,
            emailAction: "",
            emailFormSettings: {
                reply: false,
            },
            emailFormValues: {
                subject: "",
                html_body: "",
                references: "",
                in_reply_to: "",
                delivery_date: "2022-11-09T05:55:53.465Z", // ?
                email_to: [],
                email_trail_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6", // ?
                external_id: "",
                tag: "candidate",
                cc: null,
                bcc: null,
                external_id_type: "case_entity",
                placeholder_metadata: [],
            },
            roleOptions: false, // TODO move to the modal component
            isCollapsed: false,
            selectedReferee: null,
            sendToRole: "",
            activeCheck: "",
            tabs: {
                selected: 2,
                list: [
                    {
                        id: 1,
                        label: "Input",
                    },
                    {
                        id: 2,
                        label: "Checks",
                    },
                    {
                        id: 3,
                        label: "Output",
                    },
                ],
            },
            roleValue: [],
            rolesOptions: ["one", "two", "three"],
            activeTab: "one",
            lists: [],
            notes: {
                type: "internal",
                note: "",
                attachments: null,
                external_id: null,
                external_id_type: null,
            },
            clientsList: [],
            statusList: [
                {
                    id: "1",
                    label: "Completed",
                    value: "completed",
                    styles: {
                        backgroundColor: "#3E9F3C",
                        color: "#fff",
                    },
                },
                {
                    id: "2",
                    label: "Action Needed",
                    value: "action_needed",
                    styles: {
                        backgroundColor: "#F2F55A",
                        color: "#282828",
                    },
                },
                {
                    id: "3",
                    label: "On Hold",
                    value: "on_hold",
                    styles: {
                        backgroundColor: "#DCA310",
                        color: "#fff",
                    },
                },
            ],
            tenantCheckStatusList: [],
            activeIcon: "",
            cadidateFormStatusTimeline: {
                header: {
                    label: "Candidate Form Status",
                    icon: "#",
                },
                timeline: [],
            },
            checksTimeline: [
                {
                    header: {
                        label: "Gap Validation",
                        icon: "#",
                    },
                    timeline: [],
                },
                {
                    header: {
                        label: "Address History",
                        icon: "#",
                    },
                    timeline: [],
                },
            ],
            // todo fix this, use a better way to implement modals and email temps
            selectedConfig: {
                history: {
                    init: async function(id, type) {
                        this.methods.changeSettings(id, type);
                        this.props.isLoading = true;
                        const emails = await this.methods.fetchEmails(id, type);
                        // this.props.transitionList = this.transitionList;
                        this.props.emails = emails;
                        this.props.initialFilterActivity = {title: "All", value: "all"};
                        this.props.isLoading = false;
                        this.methods.scrollToSection();
                    },
                    methods: {
                        changeSettings: (id) => {
                            if (!id) {
                                this.activeCheck = "";
                                this.selectedReferee = null;
                            }
                            this.activeIcon = "history";
                            this.activeType = null;
                            this.showSubTypeCard = false;
                            this.showCandidateForm = false;
                        },
                        fetchEmails: async (payload, type) => {
                            let id = payload
                                ? payload
                                : type === "case_check"
                                ? this.$route?.query["check_id"]
                                : this.getEntityId
                                ? this.getEntityId
                                : this.$route?.query["caseid"];
                            // let id = payload || type === "case_check" ? this.$route?.query["check_id"] : this.$route?.query["caseid"];
                            let url = `/activity-history`;
                            try {
                                this.selectedConfig.history.props.transitionList = [];
                                let form_data = {
                                external_id_type: type
                                    ? type
                                    : payload
                                    ? "referee"
                                    : this.getEntityId
                                    ? "case_entity"
                                    : "candidate",
                                external_id: id,
                                activity_type: "all",
                                };
                                const { data } = await axios.post(url, form_data);
            
                                const caseLogs = await axios.get(`workflow/${this.$route?.query["caseid"]}/log`);
                                const checksLogs = await this.selectedConfig.history.methods.fetchChecksLogs();

                                this.selectedConfig.history.props.transitionList = (caseLogs?.data?.data || [])
                                    .concat(checksLogs);
            
                                const emailsData = mapEmails(data);

                                this.fetchNotificationCount(this.case_id);
                                this.fetchCaseNotificationCount(this.getEntityId);

                                return {
                                    emails: emailsData,
                                    external_id_type: form_data.external_id_type,
                                    external_id: form_data.external_id,
                                    activity_type: form_data.activity_type,
                                };
                            } catch (error) {
                                console.log(error, "Error in fetchEmails");
                            }
                        },
                        fetchChecksLogs: async () => {
                            const checksPromiseList =  this.getRefChecks.map(({ id }) => axios.get(`workflow/${id}/log`));
                            const checksResponse = await Promise.all(checksPromiseList);
                            return checksResponse.map(resp => resp?.data?.data).flat();
                        },
                        scrollToSection: () => {
                            const hashvalue = this.$route.hash?.replace("#", "");
                            setTimeout(() => {
                                const sectionele = document.querySelectorAll(
                                `[id="${hashvalue}"]`
                                )[0];
                                sectionele?.scrollIntoView({ behaviour: "smooth" });
                            }, 100);
                        },
                    },
                    component: checklist,
                    events: {
                        activityAction: this.handleChecklistAction,
                        setInitialFilterValue: this.setInitialFilterValueForActivityHistory,
                    },
                    props: {
                        isLoading: false,
                        emails: {},
                        hideHeader: false,
                        transitionList: [],
                        initialFilterActivity: {title: "All", value: "all"}
                    },
                },
                tel: {
                    init: () => {
                        let date = new Date();
                        const year = date.toLocaleString("default", { year: "numeric" });
                        const month = date.toLocaleString("default", { month: "2-digit" });
                        const day = date.toLocaleString("default", { day: "2-digit" });
                        this.$refs["call-log-modal"].callLogData.date =
                        year + "-" + month + "-" + day;
                        this.$refs["call-log-modal"].callLogData.time = new Date(
                        new Date().setHours(new Date().getHours() + 1, 0, 0)
                        );
                        this.$modal.show("call-log-modal");
                    },
                },
                task: {
                    init: () => {
                        this.$modal.show("profile-task-modal");
                    },
                },
                mail: {
                    init: () => {
                        this.resetEmailForm();
                    },
                },
                "file-manager": {
                    init: async function(id, type = "input", checktype = null) {
                        this.methods.changeSettings(id, type, checktype);
                        this.props.directory_type = type;
                        this.props.directories = [];
                        this.props.isLoading = true;
                        this.props.directories = await this.methods.fetchFiles(
                        id,
                        type,
                        checktype
                        );
                        this.props.isLoading = false;
                    },
                    methods: {
                        changeSettings: (id) => { 
                        if (!id) {
                            this.activeCheck = "";
                            this.selectedReferee = null;
                        }
                        this.activeType = null;
                        this.activeIcon = "file-manager";
                        this.showSubTypeCard = false;
                        this.showCandidateForm = false;
                        },
                        fetchFiles: async (id, type, checkType) => {
                        // const id = payload || this.$route?.query["caseid"];
                        const payload = {
                            case_entity_id: this.getEntityId,
                            case_id: this.$route?.query?.caseid,
                            // check_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                            directory_type: type,
                        };
                        if (id) {
                            if (checkType === "referee") {
                            payload.case_reference_id = id;
                            } else {
                            payload.check_id = id;
                            }
                            payload.directory_type = "input";
                            // case_reference_id
                            // this.$set(this.)
                            this.$set(
                            this.selectedConfig["file-manager"].props,
                            "selected",
                            1
                            );
                            this.$set(this.selectedConfig["file-manager"].props, "tabs", [
                            { key: 1, name: "Input", queryKey: "input" },
                            ]);
                        } else {
                            this.$set(this.selectedConfig["file-manager"].props, "tabs", [
                            { key: 1, name: "Input", queryKey: "input" },
                            { key: 2, name: "Internal", queryKey: "internal" },
                            ]);
                        }
                        try {
                            const { data } = await axios.post(
                            `/case-file-manager/tenant/entity/get-file-data`,
                            payload
                            );
                            return data?.data ?? [];
                        } catch (error) {
                            console.log(error, "[+] error while fetching files");
                        }
                        },
                    },
                    component: fileManager,
                    props: {
                        tabs: [
                        { key: 1, name: "Input", queryKey: "input" },
                        { key: 2, name: "Internal", queryKey: "internal" },
                        ],
                        selected: 1,
                        profileTab: () => {
                        return this.tabs.selected;
                        },
                        report: {
                        loading: () => this.isReportLoading,
                        generateReport: this.generateReport,
                        },
                        showTabs: true,
                        directories: [],
                        isLoading: false,
                        directory_type: "input",
                        readOnly: !checkPermission("case.actions_external"),
                    },
                },
                // overview: {
                //   action: () => {
                //     this.activeCheck = "";
                //   },
                //   component: overview,
                //   props: {

                //   }
                // }
            },
            activeCheckSubtype: null,
            pdfLink: null,
            pdfReportName: null,
            isReportLoading: false,
            activeClient: "",
            isBrowseDirModalOpen: false, // TODO move to modal component
            emailTemplates: [],
            sequenceTemplates: [],
            activeCallLog: null,
            currentRefereeForm: [],
            currentRefereeFormLoading: false,
            show_referee_form: false,
            fileUploadField: null,
            isTimelineLoading: false,
            activeType: null,
            nonRefCheckData: null,
            showSubTypeCard: false,
            candidateData: null,
            showCandidateForm: false,
            CaseCheckFormPreviewData: [],
            CaseCheckFormPreviewLoader: false,
            activeTaskData: null,
            emailActions: {
                task: false,
                date: null,
                time: null,
                task_assignee_id: null,
                notes: "",
                id: null,
                task_assignee_type: null,
                isClicked: false,
            },
            notesAction: {
                task: false,
                data: null,
                time: null,
                task_assignee_id: null,
                id: null,
                task_assignee: null,
                task_assignee_type: null,
            },
            assigneeOptions: [],
            loadingStates: {
                email: false,
                note: false,
                allocation: false,
                formData: false,
            },
            allTeams: [],
            allUsers: [],
            isLoading: false,
            dbsStatus: null,
            move_to_selected_activity_history: null,
            gapErrors: {},
            modalConfirmData: {
                config: {
                showHeader: true,
                },
            },
            moreOptions: [
                { id: 1, label: "Case Allocation", value: "case-allocation" },
            ],
            allSequenceTemplates: [],
            isSequenceModalOpen: false,
            currentSequence: {},
            isShowStopSequence: false,
            client_id: "",
            updatedSequenceTemplatesVal: [],
            currSeqAllTemplates: [],
            isLoader: false,
            gapErrorMsg: "",
            isSystemIntegratedCheck: false,
            systemIntegratedCheckName: {},
            neo_case_id: "",
            isShowGDC: false,
            isShowGDCBusiness: false,
            selectedIDTab: "",
            gdcUserData: [],
            searchQueryData: null,
            gdcBusinessData: null,
            gdcIdData: null,
            showOutputScreen: false,
            currentOutputData: null,
            transitionsList: [],
            isNonReference: false,
            eddType: null,
            eddTypeOption: [
                // { name: "L1", id: "L1" },
                // { name: "L2", id: "L2" },
                // { name: "L3", id: "L3" },
                // { name: "L4", id: "L4" }
            ],
            eddInfoResultExpand: false,
            eddReportExpand: true,
            isShowEDD: false,
            showEddSearchResult: false,
            checkTitleEDD: false,
            eddDropdown: true,
            eddProductListLoading: false,
            eddProductList: [],
            eddSearchResultLoading: false,
            eddSearchResult: {},
            eddResultLoading: false,
            existingEddSearchResult: {},
            fieldData1: {},
            eddResearchLink: "",
            showCloseIconInCaseOfEDD: false,
            showCaseOfEDD: false,
            redirectCode: null,
            addEntityWorkflow: false,
            packagesList: [],
            // fieldData: {"value":"aeb0295f-b868-485c-9159-55a8499a3918.pdf","form_entity_type":"FIELD","field_id":"aa3791ae-c13d-4724-b13a-4e9cbb1445bd","field_fe_id":"aa3791ae-c13d-4724-b13a-4e9cbb1445bd","order":100,"label":"EDD Report","description":null,"placeholder":"","field_type":"File","field_base_type":"File","type":"FILE","mandatory":true,"multi":false,"editable":true,"visible":true,"block_visibility":false,"field_repeat_max":1,"field_repeat_min":1,"use_value_from":null,"field_validation_regex":"","render_conditionally":false,"condition":null,"gap_validation":null,"error":null,"options":[],"field_internal_name":"edd_report","meta":null,"is_comment":true,"is_hide_duplicate_field":false,"is_editable":false,"field_serial":1,"path":["65731952-7d9a-4345-84b4-434b478ea9b6","aa3791ae-c13d-4724-b13a-4e9cbb1445bd"],"pathString":"65731952-7d9a-4345-84b4-434b478ea9b6/aa3791ae-c13d-4724-b13a-4e9cbb1445bd","pathParent":["65731952-7d9a-4345-84b4-434b478ea9b6"],"pathParentString":"65731952-7d9a-4345-84b4-434b478ea9b6","index":0,"is_copy":false,"ui_error":false,"ui_error_msg":""}
        };
    },

    async created() {
        EventBus.$on("refreshHistory", (data) => {
        console.log(data);
        this.selectedConfig["history"].init();
        });

        EventBus.$on("bounceBackEmail", async () => {
            if (this.tabs.selected === 1) return;
            await this.selectTab(1);
            this.isCustomizedFormsOpened = true;
        });

        EventBus.$on("onSequenceReschedule", async (dataCollectionId) => {
          await this.fetchDataCollectionSingleSequence(dataCollectionId);
        });
    },

    beforeDestroy() {
        EventBus.$off("refreshHistory");
        EventBus.$off('fetchFileDetails');
        EventBus.$off("bounceBackEmail");
        EventBus.$off("onSequenceReschedule");
    },

    //* /////////////////// MOUNTED //////////////////////////////////
    async mounted() {
        this.isTimelineLoading = true;
        this.loadingStates.formData = true;
        // this.fetchFormPreview();
        this.actionType = this.$route.query?.tabType;
        this.case_id = this.$route.query.caseid;
        await this.updateCaseEntities();
        this.setSelectedEntity(this.caseEntity);
        await this.setEntityData();
        await this.getEddProductList();
        await this.fetchCasePersonalData();
        if (this.getCaseData.client_id)
            await this.fetchPackages(this.getCaseData.client_id);
        const relationsAttributesData = await getrelationAttributes();
        const iconsdata = await getIconsData();
        // const socialPlatformIcons = await getSocialPlatforms();
        // const entitiesIdentifiers = await fetchEntityIdentifiers(this.getNeoCaseId);

        this.$store.commit("SET_ENTITIES_RELATIONS_ATTRIBUTES_DATA", relationsAttributesData?.data?.data?.data ?? {});
        this.$store.commit("SET_ICONS_DATA", iconsdata?.data.icons_data ?? []);
        // this.$store.commit("SET_SOCIAL_PLATFORMS", socialPlatformIcons?.data ?? []);
        // this.$store.commit("SET_NODES_CONNECTIONS_INFO")

        // await this.fetchCompanyProducts();
        // await this.getToolsStatus()
        await this.getQueriesResultsCount(this.currentCheckName)
        await this.getToolsSavedData()
    },

    watch: {
        content(val) {
            this.delta = val;
        },
    },

    methods: {
        ...mapActions([
            'fetchRefChecks',
            "fetchCaseNotificationCount",
            "fetchNotificationCount",
            "setSelectedEntity"
        ]),

        async handlePlusButton() {
            this.addEntityWorkflow = true;
            this.entityType = "";
            this.$router.push({
                query: {
                    clientId: this.client_id,
                    caseid: this.case_id,
                },
            });
            if (this.tabs.selected !== 1)
                await this.selectTab(1, false);

            if (this.$refs["case-creation-flow"])
                this.$refs["case-creation-flow"].selectedEntityId = null;
        },

        exitAddEntity() {
            this.addEntityWorkflow = false;
        },

        async addEntity(newEntity) {
            this.entityList.push(newEntity);
            await this.updateCaseEntities();
            this.handleEntityTabClick(newEntity);
        },

        async fetchPackages(client_id) {
            try {
                this.packages = [];
                this.loaders.packages = true;
                const { data } = await axios(`screening-package/client/${client_id}`);
                this.packagesList = data;
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.loaders.packages = false;
        },

        setInitialFilterValueForActivityHistory() {
            this.$set(this.selectedConfig.history.props, 'initialFilterActivity', {title: "All", value: "all"});
        },
        async setEntityData() {
            if (checkPermission("case.read_check_screen")) {
                await this.fetchRefChecks({
                    caseId: this.$route.query.caseid,
                    entityId: this.getEntityId,
                    caseEntity: this.caseEntity
                });
            }

            this.client_id = this?.$route?.query.clientId;

            if (!this.getTenantId) {
                await this.$store.dispatch("fetchTenantId");
            }

            this.systemIntegratedCheckName = this.getRefChecks.find(
                (type) => type.check_category == "Neotas Integrated Check"
            );
            this.fetchTemplateList();
            this.fetchSequenceList();
            this.fetchAssignee();
            await this.fetchTimeline();

            const checkId = this.$route.query.check || null;
            const type = this.$route.query.type || null;
            const check_id = this.$route.query.check_id || null;

            if (checkId) {
                const refchecks = this.getRefChecks.find(
                (chk) => chk.check_id === checkId
                );
                if (refchecks) {
                const { check_name, stage_id, integration_source_id, status, statuses } = refchecks;
                this.isClicked({
                    key: checkId,
                    type,
                    title: check_name,
                    stage_id,
                    id: check_id,
                    integration_source_id,
                    status, 
                    statuses
                });
                } else {
                this.isClicked({
                    key: 'overview',
                    type: 'static',
                    subtype: "",
                    stage_id: "",
                    title: "",
                    id: "",
                    integration_source_id: "",
                    refetch: true,
                });
                }
            } else if (this?.actionType == "additional_form") {
                this.selectedConfig["history"].init();
            } else if (!this.activeCheck) {
                this.activeCheck = "overview";
            }

            if (this.$route.query.action) {
                let active_action = this.$route.query.action;
                if (checkPermission("case.activity_history"))
                this.handleSelectIconList(active_action);
            }

            if (this.$route.query.additional_form_id) {
                let additional_form_id = this.$route.query.additional_form_id;
                await this.selectTab(1, false);
                this.selected_form_user = this.computedUserList.find(
                (user) => user.id === additional_form_id
                );
                // this.selected_dbs_form = this.computedFormList.find(
                //   (user) => user.id === additional_form_id
                // );
                // await this.fetchNewUserForm(this.selected_dbs_form);

            }

            await this.fetchCaseNotificationCount(this.getEntityId);

            if (this.$route.hash) {
                this.move_to_selected_activity_history = this.$route.hash.replace(
                "#",
                ""
                );
            }

            this.isTimelineLoading = false;
        },

        redirectToEntity(entityId = this.caseEntity.id, isSameRoute = false) {
            const newQuery = {
              clientId: this.client_id,
              caseid: this.case_id,
              entityId,
            }
            const sameQuery = {
              ...this.$route.query,
              entityId,
            }

            this.$router.push({
              query: isSameRoute ? sameQuery : newQuery,
            });
        },

        // wrapper with an extended functionality, may be renamed
        async handleEntityTabClick(entity, init) {
          this.handleEntityClick(entity);

          switch (this.tabs.selected) {
            case 1:
              await this.handleInputTabClick();
              break;
            case 2:
              await this.handleChecksTabClick(init);
              break;
            case 3:
              await this.handleOutputTabClick();
              break;
            default:
              break;
          }
        },
    
        async handleInputTabClick() {
          this.isCollapsed = false;
          await this.fetchFormPreview();
          await this.fetchAdditionalForms();
          await this.fetchDataCollections();
          await this.setCustomizedFormEntityData();
          await this.fetchDataCollectionSequences();
        },
    
        async handleChecksTabClick(init) {
          this.isCollapsed = false;

          if (init) {
            this.activeCheck = 'overview';
          } else if (this.activeCheck != 'overview') {
            await this.fetchCaseCheckFormPreview();
          }
          
          await this.setEntityData();
        },
    
        async handleOutputTabClick() {
          await this.fetchFileDetails();
        },
    
        async fetchFileDetails() {
          EventBus.$emit("fetchFileDetails");
          await this.selectedConfig["file-manager"].init(null, 'output');
        },
    
        async fetchCaseEntities() {
          try {
            this.loaders.entities = true;
            this.caseEntitiesData = await getCaseEntities(this.case_id);
          } catch (err) {
            console.error("err", err);
          } finally {
            this.loaders.entities = false;
          }
        },
        async handleEntityClick(item) {
            this.setSelectedEntity(item);
            if (this.$refs["case-creation-flow"])
                this.$refs["case-creation-flow"].selectedEntityId = item.id;
            
            this.addEntityWorkflow = false;
            this.entityType = item.entity_type;
            if (this.getEntityId === item.id) return;

            this.redirectToEntity(item.id);
        },

        async updateCaseEntities(silent) {
            await this.fetchCaseEntities();
            this.fillEntityBarData(silent);
        },

        fillEntityBarData(silent) {
            const { case_entities } = this.caseEntitiesData;

            if (!silent) {
               this.entityList = [];
               this.entityList.push(
                    ...case_entities.map((entity) => {
                        const entityType = this.getEntityTypeName(entity);

                        return {
                            ...entity,
                            name: entity.entity_name,
                            entity_type: entityType,
                            component: this.entityTypeToIcon[entityType],
                        }
                    })
                )

                if (this.getEntityId !== this.caseEntity.id) {
                    this.redirectToEntity(this.caseEntity.id, true);
                }
            } else {
                this.entityList.forEach(entity => {
                    const entityName = case_entities.find(x=>x.id == entity.id)?.entity_name;
                    entity.entity_name = entityName || '';
                    entity.name = entityName || '';
                })
            }
        },

        // TODO: Move API integration related to the Customized Forms to the separate component
        async fetchAdditionalForms() {
            try {
                this.customizedFormLoaders.loading = true;
                const resp = await axios.get(this.getApiUrl("fetchAdditionalForms"));
                this.additionalForms = resp?.data?.data || [];

                this.selected_dbs_form = this.selectedCustomizedForm;
            } catch (error) {
                this.$toast.error(getErrorMessage(error) || "Failed to load case additional forms");
            } finally {
                this.customizedFormLoaders.loading = false;
            }
        },

        async setCustomizedFormData() {
            try {
                this.customizedFormLoaders.loading = true;
                this.customizedFormData = {};

                const sequenceList = await axios.get(
                    this.getApiUrl("setCustomizedFormData"),
                    { params: { external_id: this.getTenantId } }
                );

                this.customizedFormData.sequenceList = sequenceList?.data ?? [];

            } catch (error) {
                this.$toast.error(getErrorMessage(error) || "Failed to load form data");
            } finally {
                this.customizedFormLoaders.loading = false;
            }
        },

        async addCollectInfoUser(payload) {
            const { $event } = payload;
            this.customizedFormData.userList.splice(0, 0, $event);
        },
    
        async handleUpdateCustomizedForms(payload) {
          this.customizedFormModel = payload;
        },
    
        async setCustomizedFormEntityUsers() {
            const userList = await axios.get(this.getApiUrl("setCustomizedFormEntityData"));
    
            this.customizedFormData.userList = userList?.data || [];
            
            for (const { id } of this.currentEntityCollectInfo) {
              const formUser  = this.currentEntityCollectInfo.find(data => data.id == id)?.email;
              const formUserList = this.customizedFormData.userList.filter(email => email == formUser);
              this.$set(this.dcrUsers, id, formUserList);
            }
        },

        async setCustomizedFormEntityData() {
            try {
                this.customizedFormLoaders.loading = true;

                await this.setCustomizedFormEntityUsers();

                this.customizedFormData.formList = [
                  {
                    label: "Customized Form",
                    value: this.currentEntity?.form_id,
                    id: this.currentEntity?.form_id,
                  },
                ];
            } catch (error) {
                console.log(error);
                this.$toast.error(getErrorMessage(error) || "Failed to load case emails");
            } finally {
                this.customizedFormLoaders.loading = false;
            }
        },

        async fetchDataCollections() {
            try {
                this.customizedFormLoaders.loading = true;
                const dataCollection = await axios.get(this.getApiUrl("fetchDataCollections"), {
                  params: {
                    case_entity_id: this.getEntityId,
                    form_id: this.currentEntity.form_id,
                  },
                });
                this.currentEntityCollectInfo = dataCollection?.data?.data || [];
    
                if (!this.currentEntityCollectInfo?.length && this.isCustomizedFormsOpened) {
                  this.isCustomizedFormsOpened = false;
                }
            } catch (error) {
                this.$toast.error(getErrorMessage(error) || "Failed to load case data collection requests");
            } finally {
                this.customizedFormLoaders.loading = false;
            }
        },

        async fetchDataCollectionSequences() {
            try {
                this.customizedFormLoaders.loading = true;
                const promiseList = this.currentEntityCollectInfo
                    .map(info => axios.get(`email-sequence/data-collection/${info.id}/sequence`));
    
                const dcrSequencesResponse = await Promise.all(promiseList);
                this.setEntityDataCollectionSequences(dcrSequencesResponse);
    
            } catch(error) {
                this.$toast.error(getErrorMessage(error) || "Failed to load case data collection sequences");
            }  finally {
                this.customizedFormLoaders.loading = false;
            }
        },

        async fetchDataCollectionSingleSequence(dataCollectionId) {
            try {
                const { data } = await axios.get(`email-sequence/data-collection/${dataCollectionId}/sequence`);
                const [ emailsData ] = mapEmails(data?.data);
                this.$set(this.dataCollectionSequences, dataCollectionId, emailsData ?? null);
                delete this.dcrUsers.newForm;
            } catch(error) {
                this.$toast.error(getErrorMessage(error) || "Failed to load case data collection sequences");
            }
        },

        setEntityDataCollectionSequences(dcrSequencesResponse) {
            for (const [index, value] of dcrSequencesResponse?.entries()) {
                const [ emailsData ] = mapEmails(value?.data?.data);
                this.$set(
                    this.dataCollectionSequences,
                    this.currentEntityCollectInfo[index].id,
                    emailsData ?? null
                );
            }
        },

        async addNewDataCollection(payload) {
            this.customizedFormLoaders.updating = true;
  
            try {
                const { data } = await axios.post(`email-sequence/data-collection`, [{
                case_id: this.case_id,
                active: true,
                case_entity_id: this.getEntityId,
                email: payload.user,
                email_sequence_id: payload.sequence,
                process_instantly: true,
                forms: [{
                    form_id: payload.form,
                    role: payload.role,
                    form_type: "CUSTOMIZED_CASE_ENTITY_FORM",
                }],
            }]);

            console.log("addNewDataCollection", data)

                if (data?.data) {
                    this.$toast.success("New Customized form successfully created");
                    const [ newItem ] = data.data;
                    this.currentEntityCollectInfo.push(newItem);
                    await this.setCustomizedFormEntityData();
                    await this.fetchDataCollectionSingleSequence(newItem.id)
                }

            } catch (error) {
                console.error("Failed to add data collection request", error);
                this.$toast.error(getErrorMessage(error) || "Failed to create Customized form");
            } finally {
            this.customizedFormLoaders.updating = false;
            }
        },

        async updateCustomizedForms(changedList = []) {
            try {
                this.customizedFormLoaders.updating = true;
                const payloadList = changedList.map((el) => {
                const {
                    id: data_collection_id,
                    form: form_id,
                    role,
                } = el;
                return {
                    data_collection_id,
                    form_id,
                    role,
                }
                });
                const promiseList = payloadList.map((elPayload) => axios.put(`email-sequence/data-collection/form-role`, elPayload));
                // update all changed forms:
                await Promise.all(promiseList);
                this.$toast.success("Form permissions updated successfully");
            } catch (error) {
                console.error("Failed to save form permissions", error);
                this.$toast.error(getErrorMessage(error) || `Failed to save form permissions`);
            } finally {
                this.customizedFormLoaders.updating = false;
            }
        },
        // END TODO

        checkPermission,
        async sendLinkTo(value) {
            let finalData;
            let caseId = this.$route.query.caseid;
            try {
                let url = `/integrations/hooyu/${caseId}`;
                let { data } = await axios.get(url);
                finalData = data;
            } catch (error) {
                console.log("uuu", error);
            }
            let Thedata = finalData.raw.payload;
            if (value === 1) {
                this.environment = "CVI-UK-1DOC-APP";
                console.log("value1", value);
            } else {
                this.environment = Thedata.environment;
                console.log("popop", Thedata.environment);
                console.log("value2", value);
            }
            try {
                let url = `/integrations/hooyu/${caseId}/run/cvi?force_generate_link=true&environment=${this.environment}&analystScreen=true&resend=true`;
                await axios.get(url);
                if (value === 1) {
                this.$toast.success("Send Link Successfully");
                } else {
                this.$toast.success("Resend Successfully");
                }
            } catch (error) {
                console.log("error");
            }
        },
        handleScroll(event) {
            const scrollableDiv = this.$refs.scrollableDiv;
            const scrollTop = scrollableDiv.scrollTop;
            if (scrollTop > 20) {
                this.$set(
                this.selectedConfig[this.activeIcon].props,
                "hideHeader",
                true
                );
                event.stopPropagation();
            } else {
                this.$set(
                this.selectedConfig[this.activeIcon].props,
                "hideHeader",
                false
                );
                event.stopPropagation();
            }
            event.stopPropagation();
        },
        getNeoCaseID(val) {
            this.neo_case_id = val;
        },
        async startResearch() {
            try {
                let url = `/case/${this.case_id}/generate-ip-redirect-uri`;
                let data = await axios.post(url);
                this.redirectCode = data.data.code;
                window.open(data.data.application_base_url+data.data.uri+'?code='+data.data.code,'_blank')
              } catch (error) {
                console.log("error :>>", error);
              }
        },
        sequenceTempAfterSendingEmail(val) {
            this.updatedSequenceTemplatesVal = val;
        },
        getHooyuData(newResend) {
            this.isResend = newResend;
        },
        SearchModal() {
            this.isSearchModal = !this.isSearchModal;
        },
        async getCaseDetails() {
            try{
                let url = `/case/${this.$route?.query["caseid"]}/entity/${this.getEntityId}/subject-emails`;
                const response = await axios.get(url);
                this.candidateData = response?.data
                console.log(this.candidateData)
            } catch(err) {
                console.log("err", err);
            }
            // this.candidateData = data;
        },

        fetchFileName(event, id) {
            // code for attached file names
            const { files } = event.target;

            if (id == "mail_attachments") {
                this.attachedFiles = files;
                this.attachments.mail_attachments = files.length;
            } else if (id == "notes_attachments") {
                this.notes.attachments = files;
                this.attachments.notes_attachments = files.length;
            }
        },
        addCaseFiles(event) {
            this.attachedFiles = [...this.attachedFiles, ...event];
            this.attachments.mail_attachments = this.attachedFiles.length;
            this.$refs["email-attachments-modal"].onClose();
        },
        removeAttachment(index) {
            this.attachedFiles.splice(index, 1);
            this.attachments.mail_attachments -= 1;
        },
        handleClick(name, svg) {
            this.dummystatus = name;
            this.svg = svg;
        },
        async selectInputTab(addEntityWorkflow) {
            this.addEntityWorkflow = addEntityWorkflow;
            this.selectTab(1, !addEntityWorkflow);
            await this.updateCaseEntities();
        },
        async redirectToInput(card) {
            this.additionFormLoader = true;
            await this.selectTab(1, false);
            let selectedObject = this.form_user_list?.find(
                (el) => el.user_id == card.assignee_id && el.id == card.object_id
            );
            this.selected_form_user = selectedObject?.user_name;
            // this.selected_dbs_form = selectedObject;
            await this.fetchNewUserForm(selectedObject);
            this.additionFormLoader = false;
        },
        async selectTab(id, refetch = true) {
            
            if (this.isTimelineLoading) {
                return;
            }
            // this.clearDbsFormInput();
            // this.selected_dbs_form = null;
            this.selected_form_user = null;

            // Show tab input and internal based on selected tab input check output 
            // if output then no need to show tab
            this.$set(
                this.selectedConfig["file-manager"].props,
                "showTabs",
                id !== 3
            );

            this.tabs.selected = id;

            // if (this.activeIcon && id !== 1) {
            //   let type = null;
            //   if (this.activeIcon === "file-manager") {
            //     // this.resetActiveIcon();
            //     this.$refs.activeComp.resetState();
            //     if (id === 3) {
            //       type = "output";
            //     } else {
            //       type =
            //         this.selectedConfig["file-manager"].props.selected === 1
            //           ? "input"
            //           : "internal";
            //     }
            //   }
            //   await this.selectedConfig[this.activeIcon].init(null, type);
            // } 
            
            if (id === 1 && refetch) {
              await this.fetchFormPreview();
              await this.fetchAdditionalForms();
              await this.setCustomizedFormData();
              await this.fetchDataCollections();
              await this.setCustomizedFormEntityData();
              await this.fetchDataCollectionSequences();
                // if (!(this.form_user_list && this.form_user_list.length > 0)) 
                // await this.getAdditionalFormInput(this.case_id);
            } else if (id === 2) {
                this.addEntityWorkflow = false; 
                // refetch selected check data if it is preselected on tab change
                if (this.activeCheck && this.activeCheck !== 'overview') {
                    const selectedCheck = this.getRefChecks.find(
                        (el) => el.check_id === this.activeCheck
                    );
                    this.isClicked({
                        key: selectedCheck.check_id,
                        type: selectedCheck.check_category,
                        subtype: selectedCheck.check_subtype,
                        stage_id: selectedCheck.stage_id,
                        title: selectedCheck.check_name,
                        id: selectedCheck.id,
                        integration_source_id: selectedCheck?.integration_source_id,
                        refetch: true,
                        status: selectedCheck.status,
                        statuses: selectedCheck.statuses
                    });
                } else {
                    this.isClicked({
                        key: 'overview',
                        type: 'static',
                        subtype: "",
                        stage_id: "",
                        title: "",
                        id: "",
                        integration_source_id: "",
                        refetch: true,
                    });
                    this.activeIcon = "";
                    this.setEntityData();
                }
            } else if (id === 3) {
                this.addEntityWorkflow = false;
                EventBus.$emit("fetchFileDetails");
                await this.selectedConfig["file-manager"].init(null, 'output');
            }
        },
        collapse() {
            this.isCollapsed = !this.isCollapsed;
        },
        async handleRoles(role) {
            this.emailFormValues.email_to = [];
            if (role === "client") {
                this.clientEmails = [];
                const case_id = this.$route.query.caseid;
                const url = `/case/${case_id}/client-emails`;
                try {
                    const data = await axios.get(url);
                    this.clientEmails = data.data;
                    this.emailFormValues.email_to = this.clientEmails;
                } catch (error) {
                    console.error("[+] error in fetching form data", error);
                }

                if (this.candidateData?.client_email) {
                    let match = -1;
                    match = this.emailFormValues.email_to.findIndex(
                        (el) => el.email === this.candidateData?.client_email
                    );
                    if (match > -1)
                        this.emailFormValues.email_to[match].type.push("Client");
                    else
                        this.emailFormValues.email_to.push({
                        email: this.candidateData?.client_email,
                        type: ["Client"],
                        });
                }
            } else if (role === "candidate") {
                await this.getCaseDetails();
                if(this.candidateData?.length) {
                    this.candidateData.forEach((el) => {
                        this.emailFormValues.email_to.push({
                            email: el.email,
                            type: "Subject",
                        });
                    })
                }
            }
        },
        // triggers when sidebar check card is clicked
        async isClicked({
            key,
            type,
            subtype,
            stage_id,
            title,
            id,
            card_id,
            status,
            statuses,
            integration_source_id,
        }) {
            this.checkTitleEDD =
                integration_source_id === EDD_CHECK_ID ? true : false;
            if (this.isTimelineLoading) {
                return;
            }
            if (type == "Neotas Integrated Check") {
                this.isSystemIntegratedCheck = true;
                this.isShowEDD = integration_source_id === EDD_CHECK_ID ? true : false;
                if (this.isShowEDD) {
                this.showCandidateForm = false;
                this.showSubTypeCard = false;
                this.showCaseOfEDD = false;
                await this.fetchEddResult();
                }
            } else {
                this.isSystemIntegratedCheck = false;
                this.isShowEDD = false;
            }

            if (type == "integrated" && title == "ID Verification Check") {
                this.isShowGDC = true;
            } else {
                this.isShowGDC = false;
            }
            if (type === "Non Reference") {
                this.isNonReference = true;
            } else {
                this.isNonReference = false;
            }

            if (type == "integrated" && title == "Business Verification Check") {
                this.isShowGDCBusiness = true;
            } else {
                this.isShowGDCBusiness = false;
            }
            this.selectedReferee = null;
            // this.showCandidateForm = false
            // this.showSubTypeCard = false
            // this.showSubTypeCard = false
            if (
                this.activeCheck === key &&
                key !== "add-check" &&
                title != "ID Verification Check"
            )
                return;
            this.activeCheck = key;
            if (key === "add-check") {
                this.$refs["add-check-modal"].showCheckModal();
                return;
            }
            this.$router.push({
                query: {
                    caseid: this.case_id,
                    check: key,
                    clientId: this.client_id,
                    check_id: id,
                    type: type,
                    entityId: this.getEntityId,
                    check_name: this.getIntegratedToolName(title)
                },
            });
            this.clientsList = [];

            if (this.$route.query?.check_name === "search-engine-tool") 
                this.isCollapsed = true;
            else this.isCollapsed = false;

            this.activeCheckSubtype = subtype || null;
            this.activeIcon = "";
            this.activeClient = "";
            this.showCandidateForm = false;
            //run dbs for first time
            if (this.integratedCheckSubType == "dbs") {
                await this.runDBSonMount();
            }
            if (key === "overview") await this.fetchTimeline();
            if (type) {
                type = type.toLowerCase();
                this.activeType = type;
                if (type === "reference") {
                this.component_type = "card";
                } else if (type === "candidate") {
                // to do: change to something else according to api
                this.component_type = "card-short";
                } else {
                this.component_type = "non-reference-card-short";
                }
            }
            const case_id = this.$route.query.caseid;
            if (
                type !== "static" &&
                type !== "non reference" &&
                type !== "integrated" &&
                type !== "neotas integrated check" &&
                type !== "integrated check"
            ) {
                await this.fetchRefereeStatuses();
                await this.fetchClientList(case_id, key, card_id);
            } else if (
                ["non reference", "integrated", "neotas integrated check", "integrated check"].includes(
                type
                )
            ) {
                this.activeClient = id;
                this.nonRefCheckData = {
                title,
                stage_id,
                id,
                key,
                checked: true,
                integration_source_id,
                status,
                statuses
                };

                this.showCandidateForm = this.isShowEDD ? false : true;
                await this.fetchCaseCheckFormPreview();
                
                if (this.isShowGDC || this.isShowGDCBusiness || this.isShowEDD) {
                this.showSubTypeCard = false;
                } else {
                this.showSubTypeCard = true;
                }
                // this.isShowGDC || this.isShowGDCBusiness ? this.showSubTypeCard = false : this.showSubTypeCard = true ;
            } else {
                await this.fetchRefereeStatuses();
            }
            // console.log("this.activeCheck", this.activeCheck)
        },

        async refetchRefChecks() {
            await this.fetchRefChecks({
                caseId: this.$route.query.caseid,
                entityId: this.getEntityId,
            });
        },

        async updateGDCStatus() {
            await this.refetchRefChecks();
            const check = this.getRefChecks.find(
                (chk) => chk.id === this.nonRefCheckData.id
            )
            console.log(check?.status)
            this.nonRefCheckData = {
                ...this.nonRefCheckData,
                stage_id: check?.stage_id,
                status: check?.status,
                statuses: check?.statuses,
            };
        },

        redirectToCheck(point, clickType) {
            const refchecks = this.getRefChecks.find(
                (chk) => chk.check_id === point.check_id
            );
            const {
                id,
                check_name,
                stage_id,
                check_category,
                check_subtype,
                integration_source_id,
                status, 
                statuses
            } = refchecks;

            if (clickType == "timeline") {
                if (point?.title == "Unaccounted") {
                return;
                }

                this.isClicked({
                key: point.check_id,
                type: check_category,
                subtype: check_subtype,
                stage_id: stage_id,
                title: check_name,
                id: id,
                integration_source_id,
                card_id: point.case_reference_id,
                status, 
                statuses
                });
                if (check_category === "Reference")
                this.handleCardClicked(point.case_reference_id, clickType);
            } else {
                this.isClicked({
                key: point.check_id,
                type: point.check_category,
                subtype: point.check_subtype,
                stage_id: point.stage_id,
                title: point.title,
                id: point.id,
                integration_source_id: point?.integration_source_id || integration_source_id,
                status, 
                statuses,
                });
                if (point.check_category === "Reference")
                this.handleCardClicked(point.id);
            }
        },
        async fetchClientList(case_id, key, card_id) {
            try {
                const { data } = await axios(`case/${case_id}/reference-check/${key}`);
                if (data && data.data)
                this.clientsList = data.data.map((el) => ({
                    ...el,
                    id: el.id,
                    name: el.title || "-",
                    email: el.referee_email || "-",
                    status: el.status_name,
                    refree: {
                    name: el.referee_name || "-",
                    phone: el.referee_phone || "-",
                    email: el.referee_email || "-",
                    },
                    checked: el.id === card_id || false,
                }));
            } catch (error) {
                console.log("fetchClientList error :>> ", error);
            }
        },
        handleCloseModal(modal_name) {
            this.$modal.hide(modal_name);
            // this.resetEmailForm();
        },
        async handleSelectIconList(modal_name) {
            this.isShowGDC = false;
            this.isNonReference = false;
            this.isShowGDCBusiness = false;
            this.isShowEDD = false;
            this.showEddSearchResult= false;
            this.handleResetCaseNotes();
            // remove if reset page settings anyway:
            // if (this.activeIcon === modal_name) return;

            this.activeIcon = modal_name;
            if (
                this.selectedConfig[modal_name] &&
                this.selectedConfig[modal_name].init
            ) {
                if (modal_name == "history") {
                this.selectedConfig[modal_name].props.emails = {};
                }
                if (modal_name === "file-manager") {
                // this.$refs?.activeComp?.resetState();
                let type = "";
                if (this.tabs.selected === 3) {
                    type = "output";
                } else {
                    type =
                    this.selectedConfig["file-manager"].props.selected === 1
                        ? "input"
                        : "internal";
                }
                await this.selectedConfig[modal_name].init(null, type);
                if (this.$refs?.activeComp) {
                    this.$refs.activeComp.resetState();
                }
                } else {
                await this.selectedConfig[modal_name].init();
                }
            }
        // this.activeCheck = "";
        // this.selectedReferee = null;
        },
        resetTaskNotes() {
            this.notesAction = {
                task: false,
                data: null,
                time: null,
                task_assignee_id: null,
                id: null,
                task_assignee: null,
                task_assignee_type: null,
            };
        },
        async createCaseNotes(closeCallback) {
            this.notes.note = this.notes.note.trim();
            if (this.notes.note.length === 0) {
                this.$toast.error("Empty Spaces are not accepted");
                return;
            }
            let notes = this.notes;
            let payload = {
                user_id: this.getUser.id,
                note_text: notes.note,
                note_type: notes.type,
                // external_id: this.getEntityId || notes.external_id || this.case_id,
                external_id: notes.external_id
                ? notes.external_id
                : this.getEntityId
                ? this.getEntityId
                : this.case_id,
                case_id: this.case_id,
                external_id_type: notes.external_id_type
                ? notes.external_id_type
                : this.getEntityId
                ? "case_entity"
                : "candidate", // If notes created from profile card panel then type = candidate else refrence
            };

            if (this.notesAction.task) {
                payload = {
                ...payload,
                assignee_type: this.notesAction?.task_assignee?.user_type,
                assignee_id: this.notesAction?.task_assignee?.id,
                target_time: this.formatDate(
                    this.notesAction.date,
                    this.notesAction.time
                ).toISOString(),
                task_title: notes.note,
                task_flag: this.notesAction.task,
                };
            }

            if (!this.notes.note || !this.notes.type)
                return this.$toast.error("Please Fill mandatory fields");
            let formData = new FormData();
            for (let key in payload) {
                formData.append(key, payload[key]);
            }
            // formData.append("user_id", payload.user_id);
            // formData.append("note_text", payload.note_text);
            // formData.append("note_type", payload.note_type);
            if (notes.attachments && notes.attachments.length > 0) {
                for (let i = 0; i < notes.attachments.length; i++) {
                const element = notes.attachments[i];
                formData.append("attachments", element);
                }
            }
            // formData.append("external_id", payload.external_id);
            // formData.append("external_id_type", payload.external_id_type);
            // formData.append("case_id", payload.case_id);
            try {
                this.loadingStates.note = true;
                let { data } = await axios.post("/case-notes", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                });
                this.handleResetCaseNotes();
                // this.handleCloseModal('notes');
                closeCallback();
                this.resetActiveIcon();
                this.fetchNotificationCount();
                this.fetchCaseNotificationCount(this.getEntityId);
                this.$toast.success(data.message || "notes created");
                this.resetTaskNotes();
            } catch (error) {
                this.$toast.error(
                error.response.data.detail || "failed to create notes"
                );
            }
            this.loadingStates.note = false;
        },
        resetEmailAttachments() {
            this.attachedFiles = [];
        },
        handleResetCaseNotes() {
            this.notes = {
                type: "",
                note: "",
                attachments: null,
            };
        },
        // reference card referee form data
        // file list
        // getFileFromFormData() {

        // },
        /***
         * this function is used for fetching both
         * files and form fields for individual referee cards
         * second param is for fetching files only altho they share the same API
         */
        async getRefereeFormData(ref_id, fetchFilesOnly = false) {
            if (!fetchFilesOnly) this.currentRefereeForm = [];
            this.currentRefereeFormLoading = true;
            this.isUploading = true;
            this.uploadingMsg = false;
            try {
                // const case_id = this.case_id
                const case_id = this.$route.query.caseid || this.case_id;
                const case_reference_id = ref_id;
                const { data } = await axios(
                `referee/referee/form/${case_id}/${case_reference_id}`
                );
                // data.form_values=data.form_values.filter((item)=>{
                //     if( item.is_comment || item.case_reference_id){
                //         return item
                //     }
                // })
                if (!fetchFilesOnly)
                this.currentRefereeForm = data.form_values.find(
                    (ele) => ele.field_type === "File" && !ele.can_approve
                );
                // const getCorrectToggle = (status) => {
                // 	// if (status) {
                this.fileUploadField = data.form_values.find(
                (ele) => ele.field_type === "File" && !ele.can_approve
                );

                if (!fetchFilesOnly)
                this.currentRefereeForm = getRefreeFormFields(data);
                this.filesList = data.file_links.map((file) => ({
                name: file.filename,
                id: file.link,
                link: file.link,
                }));
                this.currentRefereeForm?.sort((a, b) => {
                if (a.ref_field === b.ref_field) {
                    return 0;
                } else if (a.ref_field === true) {
                    return -1;
                } else {
                    return 1;
                }
                });
            } catch (error) {
                console.log("error :>> getRefereeFormData", error);
            }
            this.currentRefereeFormLoading = false;
            this.isUploading = false;
            this.uploadingMsg = false;
        },
        async UploadRefDocuments(files) {
            this.isUploading = true;
            this.uploadingMsg = true;
            const formData = new FormData();
            const case_reference_id = this.currentActiveClient.id;

            // formData.append('attachments', files)
            const params = {
                case_id: this.case_id,
                case_reference_id,
                field_id: this.fileUploadField.id,
                attachments: files,
            };
            files.forEach((file) => {
                formData.append("case_id", params.case_id);
                formData.append("case_reference_id", params.case_reference_id);
                formData.append("field_id", params.field_id);
                formData.append("attachments", file.file);
            });
            try {
                const { data } = await axios.post(
                `referee/referee/upload-docs`,
                formData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
                );
                if (data) {
                // todo remove the function getRefereeFormData to re-fetch form
                // and add file download link from the api response
                // for now its #
                await this.getRefereeFormData(case_reference_id, true);
                this.filesList = [...this.filesList];
                this.$toast.success(data?.message || "File uploaded");
                }
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.isUploading = false;
        },

        resetEmailForm(values = {}, settings = {}) {
        // if (!this.emailFormSettings.reply) return; // ?
            this.resetEmailAttachments();
            this.sendOptions = false;
            this.emailFormSettings = {
                reply: false,
                cc: false,
                bcc: false,
                ...settings,
            };
            this.emailFormValues = {
                cc: "",
                bcc: "",
                subject: "",
                html_body: "",
                references: "",
                in_reply_to: "",
                delivery_date: "2022-11-09T05:55:53.465Z", // ?
                email_to: [],
                email_trail_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6", // ?
                external_id: "",
                tag: values.external_id_type || "candidate", // ?
                external_id_type: values.external_id_type || "case_entity",
                placeholder_metadata: [],
                ...values,
            };
            this.emailActions = {
                task: false,
                date: null,
                time: null,
                task_assignee_id: null,
                notes: "",
                id: null,
                task_assignee_type: null,
                isClicked: false,
            };
            this.content = "";
            this.resetActiveIcon();
            // reset quill-editor content:
            this.$refs["mail-modal"].sendToRole = "";
            this.$refs["mail-modal"].roleOptions = false;
            this.content = this.emailFormValues.html_body;
        },

        replaceQuillPlaceholders(content) {
            let delta = content;
            /* eslint-disable no-useless-escape */
            delta = delta
                .split(/<strong style=\"color: rgb\(13, 105, 213\);\">(.*?)<\/strong>/)
                .join("");
            return delta;
            /* eslint-enable no-useless-escape */
        },

        async sendEmail(closeCallback) {
            let emailData = {};
            console.log("this.remove", this.removeHTML(this.content));
            const {
                task,
                time,
                date,
                task_assignee_id,
                notes,
                task_assignee_type,
            } = this.emailActions;
            this.emailActions.isClicked = true;
            if (
                (task &&
                (!time ||
                    !date ||
                    !task_assignee_id ||
                    !notes ||
                    !task_assignee_type)) ||
                !this.emailFormValues.email_to?.length ||
                !this.emailFormValues.subject ||
                !this.removeHTML(this.content)
            ) {
                this.$toast.error("Please fill all the mandatory fields.");
                return;
            }
            // commenting as for now to remove validation!!!
            // eslint-disable-next-line no-useless-escape
            // const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
            // if (!emailRegex.test(this.emailFormValues.email_to) || (this.emailFormSettings.cc && !emailRegex.test(this.emailFormValues.cc)) || (this.emailFormSettings.bcc && !emailRegex.test(this.emailFormValues.bcc))) return;

            let subject_tags = this.emailFormValues.subject.match(/<(.*?)>/g);
            let slash_tags = ["\t", "\n"];
            let subject = document.querySelector(".custom-quill .ql-editor")
                ?.innerText;
            for (const index in subject_tags) {
                const text = subject_tags[index];
                subject = subject.replaceAll(text, "");
            }
            for (const index in slash_tags) {
                const text = slash_tags[index];
                subject = subject.replaceAll(text, "");
            }

            if (this.emailFormSettings.reply) {
                emailData = {
                in_reply_to: this.emailFormValues.in_reply_to,
                email_trail_id: this.emailFormValues.email_trail_id,
                subject,
                email_to: this.emailFormValues.email_to.map((el) => el.email).join(),
                html_body: this.replaceQuillPlaceholders(this.delta),
                external_id:
                    this.emailFormValues.external_id || this.$route.query["entityId"],
                tag: this.emailFormValues.external_id_type,
                external_id_type: this.emailFormValues.external_id_type,
                client_id: this.$route.query["clientId"],
                cc: this.emailFormValues.cc,
                bcc: this.emailFormValues.bcc,
                placeholder_metadata: this.emailFormValues.placeholder_metadata,
                task_flag: task,
                target_time: this.formatDate(date, time).toISOString(),
                case_id: this.case_id,
                task_title: notes,
                task_assignee_id: task_assignee_id,
                task_assignee_type: task_assignee_type,
                };
            } else {
                emailData = {
                subject,
                html_body: this.replaceQuillPlaceholders(this.delta),
                email_to: this.emailFormValues.email_to.map((el) => el.email).join(),
                external_id:
                    this.emailFormValues.external_id || this.$route.query["entityId"],
                tag: this.emailFormValues.external_id_type,
                external_id_type: this.emailFormValues.external_id_type,
                client_id: this.$route.query["clientId"],
                cc: this.emailFormValues.cc,
                bcc: this.emailFormValues.bcc,
                placeholder_metadata: this.emailFormValues.placeholder_metadata,
                task_flag: task,
                target_time: this.formatDate(date, time).toISOString(),
                case_id: this.case_id,
                task_title: notes,
                task_assignee_id: task_assignee_id,
                task_assignee_type: task_assignee_type,
                };
            }
            try {
                this.loadingStates.email = true;
                if (this.attachedFiles.length) {
                const form_data = new FormData();
                for (let key in emailData) {
                    form_data.append(key, emailData[key]);
                }
                if (this.attachedFiles.length > 0) {
                    for (let i = 0; i < this.attachedFiles.length; i++) {
                    const element = this.attachedFiles[i];
                    form_data.append("files", element);
                    }
                }
                // this.attachedFiles.forEach(file => {
                //     form_data.append('files', file);
                // });
                await axios.post("email/send_with_attachment", form_data);
                } else {
                await axios.post("email/send", emailData);
                }
                this.resetEmailForm();
                this.fetchNotificationCount();
                this.fetchCaseNotificationCount(this.getEntityId);
                // this.delta = undefined;
                this.$toast.success("Email sent successfully");
                // this.handleCloseModal("mail");
                closeCallback();

                if (this.selectedConfig[this.activeIcon])
                this.selectedConfig[this.activeIcon].init();
            } catch (error) {
                let message;
                if (
                error?.response?.data?.detail &&
                typeof error?.response?.data?.detail === "string"
                )
                message = error?.response?.data?.detail;
                this.$toast.error(message || "Couldn't send email");
            }
            this.loadingStates.email = false;
        },
        async generateReport() {
            this.isReportLoading = true;
            try {
                let url = `report/generate/case?case_id=${this.$route.query.caseid}`;
                let { data } = await axios.get(url);
                // to do : remove this dummy link
                this.pdfLink = data.report_url;
                this.pdfReportName = data.output_filename;
                this.$modal.show("pdf-view-modal");
                // window.open(this.pdfLink);
            } catch (error) {
                this.$toast.error(
                error?.response?.data?.detail || "Error While creating report"
                );
            }
            this.isReportLoading = false;
        },

        async handleCardClicked(card_id) {
            if (this.activeType == "non reference") return;
            this.showSubTypeCard = this.isShowEDD ? false : true;
            if (this.integratedCheckSubType !== "hooyu") {
                this.show_referee_form = true;
                this.closeActiveComponent();
                if (this.activeClient === card_id) return;
                this.activeClient = card_id;
                this.clientsList = this.clientsList.map((el) => ({
                ...el,
                checked: el.id === card_id,
                }));
                // this.filesList = []
                // this.fileUploadField = null
                await this.getRefereeFormData(card_id);
                /// call api and stuffs here
            }
            // // do something for non reference card //
            // else {

            // }
        },

        async fetchCaseCheckFormPreview() {
            this.activeIcon = "";
            const case_id = this.case_id;
            const tenant_check_id = this.nonRefCheckData.key;
            this.CaseCheckFormPreviewLoader = true;
            try {
                const url = `case/form/${case_id}/entity/${this.getEntityId}?tenant_check_id=${tenant_check_id}`;
                const { data } = await axios(url);
                data.sections = data.sections?.map((entities) => {
                    entities.entities = entities.entities?.map((block) => {
                    if (
                        block?.form_entity_type == "BLOCK" &&
                        block.block_fe_id == null
                    ) {
                        block.block_fe_id = block?.block_id;
                    }
                    if (
                        block?.form_entity_type == "BLOCK" &&
                        block?.block_fe_id == block?.block_id
                    ) {
                        block.is_og_block = true;
                    }
                    return block;
                    });
                    return entities;
                });
                this.CaseCheckFormPreviewData = data;
                console.log(
                    "this.CaseCheckFormPreviewData",
                    this.CaseCheckFormPreviewData
                );
            } catch (error) {
                console.log(error);
            }
            this.CaseCheckFormPreviewLoader = false;
        },

        handleChange(options = {}) {
            const { id, key, value } = options;
            const object = this.currentRefereeForm.find((el) => el.id === id);
            object[key] = value;
            this.currentRefereeForm = this.currentRefereeForm.map((el) =>
                el.id === id ? object : el
            );
        },

        async deleteFile(filename) {
            let params = {
                case_id: this.case_id,
                case_reference_id: this.currentActiveClient.id,
                field_id: this.fileUploadField.id,
                file_name: filename,
            };
            try {
                const { data } = await axios.post(
                    `referee/referee/delete-docs`,
                    params
                );
                if (data) {
                    this.$toast.info("File deleted");
                    this.filesList = this.filesList.filter(
                        (ele) => ele.name !== filename
                    );
                }
            } catch (error) {
                console.log("error :>>deleteFile ", error);
            }
        },

        handleChecklistAction(e) {
            if (e.type === "reply") {
                this.emailFormSettings.reply = true;
                const values = {
                    email_to: [{
                        email: e.data.email_from,
                    }],
                    subject: e.data.subject,
                    in_reply_to: e.data.message_id,
                    email_trail_id: e.data.email_trail_id,
                    external_id: e.data.external_id,
                    // tag: e.data.tag,
                    cc: e.data.cc,
                    bcc: e.data.bcc,
                };
                const settings = {
                    reply: true,
                };
                this.resetEmailForm(values, settings);
                this.$modal.show("mail");
            }
        },
        resetActiveIcon(value = "") {
            this.activeIcon = value;
        },
        async logActivity(payload) {
            let formatedDate = this.formatDate(payload.date, payload.time);
            // TODO
            const case_id = this.$route.query.caseid;
            // console.log("this.activeCallLog", this.activeCallLog);
            let payload_data = {
                on: formatedDate,
                contacted_person: payload.contacted,
                phone_no: payload.phone,
                // external_id: this.activeCallLog ? this.activeCallLog.id : case_id,
                external_id: this.activeCallLog
                    ? this.activeCallLog.id
                    : this.getEntityId
                    ? this.getEntityId
                    : case_id,
                external_id_type: this.activeCallLog
                    ? ""
                    : this.getEntityId
                    ? "case_entity"
                    : "",
                content: payload.content,
            };
            const url = `/call_logs`;
            try {
                await axios.post(url, payload_data);
                this.$toast.success("call log saved");
                this.fetchNotificationCount();
                this.fetchCaseNotificationCount(this.getEntityId);
            } catch (error) {
                this.$toast.error("failed to create call log");
            }
        },

        formatDate(date, time) {
            let dateObj = new Date(date);
            let timeObj = new Date(time);
            let hours = timeObj.getHours();
            let minutes = timeObj.getMinutes();
            let seconds = timeObj.getSeconds();
            return new Date(dateObj.setHours(hours, minutes, seconds));
        },

        async saveTask(payload) {
            let formatedDate = this.formatDate(payload.dueDate, payload.time);
            const apiPayload = {
                external_id:
                this.activeTaskData?.id || this.getEntityId || this.case_id,
                user_id: this.getUser.id,
                task_text: payload.note,
                target_time: formatedDate,
                case_id: this.case_id,
                // visibility: "internal", //TODO: Create UI for internal and customer in task modal
                // external_id_type:  this.getEntityId ? 'case_entity' :  this.activeTaskData?.type || "candidate",
                external_id_type: this.activeTaskData?.type
                    ? this.activeTaskData?.type
                    : this.getEntityId
                    ? "case_entity"
                    : "candidate",
                assignee_id: payload?.task_assignee_id,
                assignee_type: payload?.task_assignee_type,
            };

            try {
                const url = "/case-tasks";
                await axios.post(url, apiPayload);
                this.$toast.success("Task Saved");
                this.fetchNotificationCount();
                this.fetchCaseNotificationCount(this.getEntityId);
            } catch (error) {
                this.$toast.error(
                    error.response.data.detail || "Failed to create task"
                );
            }
        },
        async fetchFormPreview() {
            try {
                const data = await axios.get(this.getApiUrl("fetchFormPreview"));
                this.formPreviewData = formatDataForFormPreview(data?.data);
                this.additional_form_key++;
            } catch (error) {
                console.error("[+] error in fetching form data", error);
                this.$toast.error(error.response.data.detail);
            } finally {
                this.loadingStates.formData = false;
            }
        },
        async saveSection(payload) {
            let sectionSaved = false;
            const url = this.selected_dbs_form?.id && this.showClearFiltersButton
                ? `case/${this.case_id}/entity/${this.getEntityId}/additional-forms/${this.selected_dbs_form.id}`
                : this.getApiUrl("saveSection");

            const body = formatSections(payload);

            try {
                const response = await axios.post(url, body);
                this.gapErrorMsg = "";
                sectionSaved = true;

                if (response) {
                    // Need to refetch case entities to reflect possible changes within the form
                    // await this.updateCaseEntities(body);
                    if (checkNeedEntitiesUpdate(body?.sections)) {
                      // TODO: temporarily disable entity update to preserve entity tabs selection
                        // await this.updateCaseEntities();
                    }
                    if (this.tabs.selected === 1) await this.updateCaseEntities(true);

                    this.$toast.success("Form section successfully updated!");
                }
            } catch (error) {
                const { error: resp } = error?.response?.data?.detail || {};
                if (resp) {
                    if (resp.code === "ERROR.GAP_VALIDATION") {
                        this.gapErrorMsg = resp.code;
                        this.scrollToInvalidField();
                        this.gapErrors = {
                            message: resp.message,
                            gaps: resp.gaps,
                            gapErrorsView: true,
                        };
                    }
                } else {
                    this.$toast.error(error.response.data.detail);
                }
                sectionSaved = false;
                throw error;
            }
            return sectionSaved;
        },
        scrollToInvalidField() {
            setTimeout(() => {
                this.$nextTick(() => {
                    document
                    .querySelectorAll(".form-field--invalid")[0]
                    ?.scrollIntoView({ behavior: "smooth" });
                });
                }, 0);
        },
        async submitCaseForm() {
            const url = this.getApiUrl("submitForm");

            try {
                const response = await axios.post(url);

                if (response) {
                    // Need to refetch case entities to reflect possible changes within the form
                    // TODO: temporarily disable entity update to preserve entity tabs selection
                    // await this.updateCaseEntities();
                    this.$toast.success(response.data.message || "Form Saved!");
                }
            } catch (error) {
                this.$toast.error(error.response.data.detail);
                return false;
            }
        },
        async saveForm() {
            try {
                // const sectionSaved = await this.saveSection(payload); // moved inside the formbuilder to handle response
                // if (sectionSaved) {
                if(this.tabs.selected !== 2) {
                    await this.submitCaseForm(this.case_id);
                }
                this.showFormSubmissionNotification = true;
                this.gapErrors = {
                    gapErrorsView: false,
                };
                
                if (this.$route.query.caseid) {
                    const entity = this.caseEntitiesData.case_entities.find((el) => el.id === this.getEntityId)
                    await this.updateCaseEntities();
                    this.handleEntityTabClick(entity);
                }
                // }
            } catch (error) {
                this.formSubmissionStatusMessage =
                    "Could not process form at the moment.please contact administrator.";
                // this.showFormSubmissionNotification=true
            }
            // this.showFormSubmissionNotification=true
        },
        async fetchTimeline() {
            this.isTimelineLoading = true;
            const case_id = this.$route.query.caseid;
            let status_timeline = {
                header: {
                    label: "Subject Form Status",
                    icon: "#",
                },
                timeline: [],
                };
                let non_timeline = {
                header: {
                    label: "Checks",
                    icon: "#",
                },
                timeline: [],
                showDates: false,
            };

            // todo instead of calling api's sequentally
            // make a Promise array and fetch them all at once
            try {
                const { data } = await axios(
                    `case/${case_id}/entity/${this.getEntityId}/events-timeline`
                );
                if (data)
                    status_timeline.timeline = data.data.map((el) => ({
                    date: new Date(el.timestamp),
                    title: el.event_name,
                    description: "",
                    color: el.status_color || "#3b2cd4",
                    orignalDate: el.timestamp,
                    }));
            } catch (error) {
                console.log("error1 :>> ", error);
            }
            try {
                const { data } = await axios(
                    `case/${case_id}/entity/${this.getEntityId}/checks-timeline`
                );
                this.checksTimeline = data.data.map((obj) => {
                    let data = {};
                    data = {
                    header: {
                        label: obj.check_name,
                        icon: "#",
                    },
                    };
                    data.timeline = obj.timeline.map((el) => ({
                        date: new Date(el.from_date),
                        title: el.title,
                        description: el.status_name,
                        color: el.status_color,
                        orignalDate: el.from_date,
                        toDate: el.to_date,
                        check_id: obj.check_id,
                        type: "gapCheck",
                        case_reference_id: el.case_reference_id,
                    }));
                    return data;
                });
            } catch (error) {
                console.log("error2 :>> ", error);
            }
            try {
                const { data } = await axios(
                `case/${case_id}/entity/${this.getEntityId}/non-timeline-checks`
            );
            if (data) {
                non_timeline.timeline = data.data.map((el) => {
                    let match = this.getRefChecks.find((chk) => chk.id === el.id);
                    if (!match) {
                        const refChecks = this.getRefChecks.filter(
                        (chk) => chk.check_category === "Reference"
                        );
                        let refCheckIndex = -1;
                        for (let index in refChecks) {
                        let refIndex = -1;
                        refIndex = refChecks[index].references.findIndex(
                            (ref) => ref.case_reference_id === el.id
                        );
                        refCheckIndex = index;
                        if (refIndex > -1) break;
                    }
                    return {
                        // adding a dummy for key and contents
                        date: new Date(),
                        orignalDate: DateTime.now().toString(),
                        title: el.check_name || "-",
                        description: el.status_name,
                        color: el.status_color || "#3b2cd4",
                        check_id: refChecks[refCheckIndex]?.check_id,
                        check_category: refChecks[refCheckIndex]?.check_category,
                        check_subtype: refChecks[refCheckIndex]?.check_subtype,
                        stage_id: refChecks[refCheckIndex]?.stage_id,
                        id: el.id,
                    };
                    }
                    if (match)
                    return {
                        // adding a dummy for key and contents
                        date: new Date(),
                        orignalDate: DateTime.now().toString(),
                        title: match.check_name || "-",
                        description: match.status.status_name,
                        color: match.status.status_colour || "#3b2cd4",
                        check_id: match?.check_id,
                        check_category: match?.check_category,
                        check_subtype: match?.check_subtype,
                        stage_id: match?.stage_id,
                        id: el.id,
                    };
                });
                }
            } catch (error) {
                console.log("error3 :>> ", error);
            }
            this.isTimelineLoading = false;
            // check if the status timeline have the timeline data 
            // if data then show the timeline card for status subject form data  else not
            if (status_timeline?.timeline?.length) {
                this.checksTimeline.push(...[status_timeline])
            }
            this.checksTimeline.push(...[non_timeline]);
            this.activeIcon = null;
        },

        closeActiveComponent() {
            this.selectedReferee = null;
            this.activeCallLog = null;
            (this.activeTaskData = null), (this.activeIcon = "");
            this.isCollapsed = false;
            this.showCandidateForm = false;
        },

        async fetchRefereeStatuses() {
            try {
                const { data } = await axios(`referee/reference/status`);
                this.statusList = data?.statuses.map((status) => ({
                ...status,
                label: status.name,
                value: status.internal_name,
                styles: {
                    backgroundColor: status.color || "#0000FF",
                    color: status.text_color || "#fff",
                },
                }));
            } catch (error) {
                console.log("error fetchRefereeStatuses :>> ", error);
            }
        },

        handleStatusChangedTest() {
            
        },

        // async handleStatusChanged(val) {
        // console.log("ref id", ref_id);

        async handleStatusChanged(val) {
            if (!val || !val.id) return;

            try {
                const url = `workflow/${this.nonRefCheckData.id}/status/${val.id}`;
                const response = await axios.post(url);
                if (response) {
                await this.updateGDCStatus();
                this.$toast.success(`Status changed successfully!`);
                }
                
            } catch (error) {
                console.log("error handleStatusChanged :>> ", error);
            }
        },

        // reference check form
        async handleForm() {
            this.isdisabledClicked = true;
            const case_id = this.case_id;
            const case_ref_id = this.currentActiveClient.id;
            const correctValue = (ref_status) => {
                // if (ref_status === 'unselected') return null
                // else
                return ref_status === "correct";
            };
            const getValue = (val) => {
                if (Array.isArray(val)) return val.join();
                else return val;
            };

            // * Refree cann't override candidates consent or signature
            const getRefAction = (form) =>
                ["signature", "consent"].includes(form.type) ? true : form.ref_action;
            let formData = this.currentRefereeForm.map((el) => ({
                ...el,
                field_id: el.id,
                field_name: el.label,
                value: getValue(el.value),
                is_correct: correctValue(el.correct) || false,
                correct_value: Array.isArray(el.correct_value)
                ? JSON.stringify(el.correct_value)
                : el.correct_value || "",
                ref_action: getRefAction(el),
            }));
            let param_data = {
                case_id: case_id,
                field_list: formData.map((el) => ({
                id: el.id,
                case_id: case_id,
                section_field_id: el.api_data.section_field_id
                    ? el.api_data.section_field_id
                    : el.api_data.form_section_field_id,
                value_str: el.value,
                value_int: el.api_data.value_int,
                block_fe_id: el.api_data.block_fe_id,
                field_fe_id: el.api_data.field_fe_id,
                serial: el.api_data.serial,
                field_serial: el.api_data.field_serial,
                case_reference_id: case_ref_id,
                Label: el.api_data.Label || el.label,
                section_field_label: el.api_data.section_field_id,
                label: el.label,
                name: el.api_data.name || el.label,
                field_type: el.api_data.field_type,
                ref_action: el.ref_action || false,
                field_type_id: el.api_data.field_type_id,
                ref_input_value:
                    el?.is_comment == ("true" || true) ? el.value : el.correct_value,
                ref_input_status: el.is_correct,
                can_approve:
                    el?.is_comment == ("true" || true)
                    ? false
                    : el.api_data.can_approve,
                })),
            };
            try {
                this.isLoader = true;
                //to do: change accoring to tenant app API for submitting form data
                const response = await axios.post(
                `referee/referee/form?screener=True`,
                param_data
                );
                if (response) {
                this.$toast.success(`Saved successfully!`);
                }
                this.isdisabledClicked = false;
                this.isLoader = false;
            } catch (error) {
                this.isLoader = false;
                this.isdisabledClicked = false;
                console.log("error :>> ", error);
            }
        },

        cancelForm() {
        // this.currentActiveClient = null
            this.activeClient = "";
            this.filesList = [];
            this.fileUploadField = null;
            this.clientsList = this.clientsList.map((el) => ({
                ...el,
                selected: false,
            }));
        },

        async getAdditionalFormInput(case_id) {
            try {
                const { data } = await axios(`case/${case_id}/additional-forms`);
                this.form_user_list = data.data || [];
            } catch (error) {
                console.log("data :>> ", error);
            }
        },
        handleChecked(ref_id) {
            console.log(ref_id);
        },
        fetchTemplateLoader(value) {
            this.isLoading = value;
        },
        async fetchTemplateList() {
            try {
                const url = `/email-template/list?external_id=${this.getTenantId}`;
                const result = await axios.get(url);
                const data = result.data.content.map((x) => ({
                ...x,
                created_at: x?.created_at
                    ? format(new Date(x?.created_at), "MMM dd yyyy")
                    : "",
                }));
                this.emailTemplates = data;
            } catch (error) {
                console.error(error.message);
            }
        },

        async fetchSequenceList() {
            try {
                const { data } = await axios.get(
                `/email-sequence/list?external_id=${this.getTenantId}`
                );
                // console.log('data :>> ', data);
                this.sequenceTemplates = data.content.map((seq) => ({
                ...seq,
                created_at: seq?.created_at
                    ? format(new Date(seq?.created_at), "MMM dd yyyy")
                    : "",
                // type: "resource",
                }));
            } catch (error) {
                console.log(error, "[+] failed to fetch sequence");
            }
        },

        async fetchTemplateData(template) {
            try {
                const res = await axios.get(`email-template/${template.id}`);
                const templateData = {
                id: res.data.id,
                content: res.data.body,
                templateName: res.data.name,
                templateSubject: res.data.subject,
                system_default: template.system_default,
                placeholder_metadata: res.data.placeholder_metadata,
                };
                return templateData;
            } catch (error) {
                console.log(error.message);
            }
        },
        async clickedEmailTemplate(template) {
            const templateData = await this.fetchTemplateData(template);
            this.content = templateData.content;
            this.emailFormValues.subject = templateData.templateSubject;
            this.emailFormValues.placeholder_metadata = templateData.placeholder_metadata;
        },

        async onSelectSequence(sequence) {
            try {
                this.currentSequence = sequence;
                const { data } = await axios.get(`/email-sequence/${sequence.id}`);
                const sequences = await Promise.all(
                data.map((x) => axios.get(`email-template/${x.template_id}`))
                );
                // this.currSeqAllTemplates = sequences.map(item => item.data.body.replace(/<[^>]*>/g, ''));
                const isShowStopSequence = sequences.some((seq) =>
                seq.data.placeholder_metadata.some((meta) => meta.type == "form")
                );
                this.isShowStopSequence = isShowStopSequence;
                this.allSequenceTemplates = sequences.map((x, indx) => ({
                    ...x.data,
                    // previous logic --> data?.find((el) => el.template_id === x.data.id)
                    ...data?.find((el) => el.number === indx+1),
                }));
                this.isSequenceModalOpen = true;
            } catch (error) {
                console.log(error, "[+] failed to fetch sequences");
            }
        },

        async onSendSeqEmail({ event }) {
            setTimeout(async () => {
                try {
                let response = await axios.post(`/email-sequence/schedule`, {
                    sequence_id: this.currentSequence.id,
                    external_id:
                    this.emailFormValues.external_id || this.$route.query["entityId"],
                    external_id_type: this.emailFormValues.external_id_type,
                    cancel_on_form_submission: event.stop_on_form_submission,
                    email_to: event.email_to,
                    client_id: this.client_id,
                    sequence_data: this.updatedSequenceTemplatesVal,
                });
                if (response) {
                    this.isSendDisabled = false;
                    this.$toast.success("Email sent successfully");
                }
                } catch (error) {
                this.isSendDisabled = false;
                this.$toast.error("failed to sent mail");
                }
                this.onHideSequenceModal(false);
            }, 0);
            // this.resetEmailForm();
            // this.delta = undefined;
            // this.handleCloseModal("mail");
            // closeModal();
        },
        onHideSequenceModal(state) {
            this.isSequenceModalOpen = state;
        },
        updatedSequenceTemplates(value) {
            this.sequenceTemplates = value;
        },
        handleClearCallActiveLog() {
            this.activeCallLog = null;
        },
        handleUserFormList(value) {
            this.selected_form_user = value;
            this.selected_dbs_form = null;
        },
        async fetchNewUserForm(value) {
            if (!value || this.selected_dbs_form?.id === value?.id) return;
            this.selected_dbs_form = value;

            if (value?.form_name === "Customized form") {
                await this.clearDbsFormInput();
                return;
            }

            this.isCustomizedFormsOpened = false;
            await this.fetchAdditionalForm();
        },

        async fetchAdditionalForm() {
            try {
                this.additionFormLoader = true;
                const resp = await axios(`case/${this.case_id}/entity/${this.getEntityId}/additional-forms/${this.selected_dbs_form.id}`);
                this.formPreviewData = resp?.data ?? [];
                this.additional_form_key++;
                this.$forceUpdate();
            } catch (error) {
                console.log("error :>> ", error);
                this.$toast.error(getErrorMessage(error) || "Failed to load case additional forms");
            } finally {
                this.additionFormLoader = false;
            }
        },

        async clearDbsFormInput() {
            this.additionFormLoader = true;
            this.selected_dbs_form = this.selectedCustomizedForm
            this.selected_form_user = null;
            // this.form_user_list = []
            await this.fetchFormPreview();
            this.additional_form_key++;
            this.additionFormLoader = false;
        },

        onPrintForm() {
            window.print();
        },
        openEmailNotes() {
            if (this.emailActions.task)
                this.$refs["email-note-modal"].showModal(this.emailActions);
        },

        async onNoteEmailSave(event) {
            this.emailActions = event;
            if (event.isEdit) {
                this.$set(
                this.selectedConfig[this.activeIcon].props,
                "isLoading",
                true
                );
                // let emails = this.selectedConfig[this.activeIcon].props.emails.emails;
                // let index = emails.findIndex((item) => item.id == event.id);
                // if (index && index != -1) {
                //     emails[index].task_text = event.notes;
                //     emails[index].assignee_id = event.task_assignee_id;
                //     emails[index].assignee_type = event.task_assignee_type;
                //     this.$set(this.selectedConfig[this.activeIcon].props.emails, "emails", emails);
                // }
                await this.onUpdateTask(event);
                await this.selectedConfig[this.activeIcon].init();
                this.$set(
                this.selectedConfig[this.activeIcon].props,
                "isLoading",
                false
                );
            }
        },

        async fetchAssignee() {
            try {
                const { data } = await axios.get(`/case/${this.case_id}/users`);
                this.assigneeOptions = data?.data ?? [];
                this.assigneeOptions = this.assigneeOptions.map((opt) => ({
                ...opt,
                user_name: opt?.user_name ?? opt.email,
                }));
            } catch (error) {
                console.log("[+] error while fetching assignee", error);
            }
        },
        onAssigneChange(/* value, type */) {
            // if(type==='email') { // TODO remove
            // 	this.emailActions.task_assignee_id = value.id;
            // 	this.emailActions.task_assignee_type = value.user_type;
            // }
        },

        async fetchAllocation(type) {
            const url = `/work-allocation/case/${this.case_id}/${type}`;
            try {
                const { data } = await axios.get(url);
                if (type === "groups") {
                this.allTeams = data ?? [];
                } else {
                this.allUsers = data ?? [];
                }
            } catch (error) {
                console.log(error, "[+] error while fetch allocation list");
            }
        },

        async onWorkAllocation() {
            this.loadingStates.allocation = true;
            await this.fetchAllocation("groups");
            await this.fetchAllocation("users");
            this.loadingStates.allocation = false;
            this.$modal.show("profile-work-allocation");
        },
        async onWorkAllocationSave({ type, status }) {
            //type: team or user
            this.loadingStates.allocation = true;
            let url = "";
            let payload = {
                case_ids: [{ id: this.case_id }],
            };
            if (type === "team") {
                url = "/work-allocation/case/tenant-group";
                payload.add_groups = status.allotted ? [status.id] : [];
                payload.remove_groups = status.allotted ? [] : [status.id];
            } else {
                url = "/work-allocation/case/tenant-user";
                payload.add_users = status.allotted ? [status.id] : [];
                payload.remove_users = status.allotted ? [] : [status.id];
            }
            try {
                await axios.post(url, payload);
                this.$toast.success("Saved Successfully!");
            } catch (error) {
                this.$toast.error("Saving Failed!");
            }
            this.loadingStates.allocation = false;
        },

        openEmailAttachments() {
            this.$refs["email-attachments-modal"].showModal();
        },

        async openFileManager() {
            this.$refs["case-files-manager--modal"].showModal();
        },

        async runDBSonMount() {
            if (this.case_id) {
                this.isLoading = true;
                try {
                let url = `/integrations/dbs/${this.case_id}/run`;
                let { data } = await axios.get(url);
                if (data.isRunDBS) {
                    this.dbsStatus = data;
                } else {
                    this.dbsStatus = null;
                }
                } catch (error) {
                this.dbsStatus = null;
                }
                this.isLoading = false;
            }
        },
        async runDBS() {
            let promise = await this.$refs.modalConfirm.show({
                message:
                "Is the ID Check clear or has the client witnessed the ID document?",
                buttonText: "Yes run DBS",
                title: "Run DBS",
            });
            if (promise) {
                if (this.case_id) {
                this.isLoading = true;
                try {
                    let url = `/integrations/dbs/${this.case_id}/run`;
                    let { data } = await axios.get(url);
                    // to do : remove this dummy link
                    this.dbsStatus = data;
                } catch (error) {
                    if (error.response.status == 400) {
                    this.$toast?.error("Failed to run DBS!");
                    } else {
                    this.$toast.error(
                        error.response.data.detail ||
                        "Something went wrong. Please try again!"
                    );
                    }
                }
                this.isLoading = false;
                }
            }
        },

        async removeBlock(payload) {
            const url = `${this.getApiUrl("removeBlock")}/${payload}`;

            try {
                await axios.delete(url);
            } catch (error) {
                this.$toast?.error(error?.response?.data?.detail);
            }
        },

        async removeField(payload) {
            const url = `${this.getApiUrl("removeField")}/${payload}`;

            try {
                await axios.delete(url);
            } catch (error) {
                this.$toast?.error(error?.response?.data?.detail);
            }
        },

        onTaskEdit(event) {
            const { assignee_id, task_text, target_time, assignee_type, id } = event;
            this.emailActions = {
                task: true,
                date: new Date(target_time),
                time: new Date(target_time),
                task_assignee_id: assignee_id,
                notes: this.removeHTML(task_text),
                id,
                task_assignee_type: assignee_type,
                isClicked: false,
                isEdit: true,
            };
            // onBeforeOpen()
            this.$refs["email-note-modal"].showModal(this.emailActions);
        },
        async onNoteDelete(event) {
            let promise = await this.$refs.modalConfirm.show({
                message: "Are you sure you want to delete this note?",
                buttonText: "Delete",
                title: "Task",
            });
            if (promise) {
                this.$set(
                this.selectedConfig[this.activeIcon].props,
                "isLoading",
                true
                );
                await this.deleteNote(event);
                await this.selectedConfig[this.activeIcon].init();
                this.$set(
                this.selectedConfig[this.activeIcon].props,
                "isLoading",
                false
                );
            }
        },

        async onTaskDelete(event) {
            let promise = await this.$refs.modalConfirm.show({
                message: "Are you sure you want to delete this task?",
                buttonText: "Delete",
                title: "Task",
            });
            if (promise) {
                this.$set(
                this.selectedConfig[this.activeIcon].props,
                "isLoading",
                true
                );
                // let emails = this.selectedConfig[this.activeIcon].props.emails.emails.filter((item) => item.id !== event.id);
                // this.$set(this.selectedConfig[this.activeIcon].props.emails, "emails", emails);
                await this.deleteTask(event);
                await this.selectedConfig[this.activeIcon].init();
                this.$set(
                this.selectedConfig[this.activeIcon].props,
                "isLoading",
                false
                );
            }
        },
        removeHTML(html) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            if (tmp.querySelector("img")) {
                return true;
            } else {
                return tmp.textContent || tmp.innerText || "";
            }
        },
        async onUpdateTask({
          id,
          notes,
          time,
          date,
          task_assignee_type,
          task_assignee_id,
        }) {
            try {
                // added a return to stop api for no date and time present
                if (!date || !time || !notes || notes == "") {
                return;
                }
                // this.formatDate(date, time).toISOString()
                let { data } = await axios.put("/case-tasks", {
                id,
                task_text: notes,
                target_time: this.formatDate(date, time).toISOString(),
                assignee_type: task_assignee_type,
                assignee_id: task_assignee_id,
                });
                this.$toast.success(data.message || "Task updated");
            } catch (error) {
                console.log(error, "[+] failed to error while updating task");
            }
        },

        async deleteNote({ id }) {
            try {
                let { data } = await axios.delete(`/case-notes/${id}`);
                this.$toast.success(data.message || "Note Deleted");
            } catch (error) {
                this.$toast.error(
                error.response.data.detail || "Failed to delete note"
                );
            }
        },

        async deleteTask({ id }) {
            try {
                let { data } = await axios.delete(`/case-tasks/${id}`);
                this.fetchCaseNotificationCount(this.getEntityId);
                this.fetchNotificationCount(this.case_id);
                this.$toast.success(data.message || "Tast Deleted");
            } catch (error) {
                this.$toast.error(
                error.response.data.detail || "Failed to delete task"
                );
            }
        },

        async onOptionClicked(item) {
            switch (item.value) {
                case "case-allocation":
                await this.onWorkAllocation();
                break;

                default:
                break;
            }
        },

        onFileManagerTabChange(tab) {
            this.$refs.activeComp.resetState();
            this.$set(this.selectedConfig["file-manager"].props, "selected", tab.key);
            this.selectedConfig["file-manager"].init(null, tab.queryKey);
        },

        async onAddNewFolder(details = {}) {
            try {
                const payload = {
                case_id: this.case_id,
                directory_type: details.directory_type,
                resource_type: "directory",
                name: details.name,
                description: details.description,
                path: `${details.content.path}/${details.name}`,
                case_entity_id: this.getEntityId,
                };
                this.$set(this.selectedConfig["file-manager"].props, "isLoading", true);
                await this.uploadDirectoryContent(payload);
                // this.$toast.success("Folder added successfully")
                await this.selectedConfig["file-manager"].init(
                null,
                details.directory_type
                );
            } catch (error) {
                console.log(error, "[+] error while adding directory");
            }
        },

        async onAddFiles({ files, content, directory_type }) {
            try {
                const formData = new FormData();
                formData.append("file", files[0].file);
                this.$set(this.selectedConfig["file-manager"].props, "isLoading", true);
                const { data } = await axios.post(
                `/case-file-manager/case/${this.case_id}/entity/${this.getEntityId}/upload-file`,
                formData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
                );
                const payload = {
                case_id: this.case_id,
                directory_type: directory_type,
                resource_type: "file",
                name: data?.data?.file_path ?? "",
                path: `${content.path}/${data?.data?.file_path ?? ""}`,
                case_entity_id: this.getEntityId,
                };
                // this.$toast.success("File Added successfully")
                await this.uploadDirectoryContent(payload);
            } catch (error) {
                this.$set(this.selectedConfig["file-manager"].props, "isLoading", false);
                this.$toast.error(
                error.response.data.detail || "Some error occured while adding file"
                );
            }
            this.$set(this.selectedConfig["file-manager"].props, "isLoading", false);
            await this.selectedConfig["file-manager"].init(null, directory_type);
        },

        async uploadDirectoryContent(payload = {}) {
            try {
                await axios.post("/case-file-manager", payload);
                this.$toast.success("File Added successfully");
            } catch (error) {
                this.$toast.error(
                error?.response.data.detail
                    ? error?.response.data.detail
                    : "Some error occured while adding a folder"
                );
            }
        },

        async onDeleteDirectory(folder) {
            try {
                this.$set(this.selectedConfig["file-manager"].props, "isLoading", true);
                const { data } = await axios.delete(`/case-file-manager/${folder.id}`);
                this.$toast.success(
                data?.message ? data?.message : "Deleted successfully"
                );
            } catch (error) {
                this.$toast.error(
                error?.response?.data?.detail
                    ? error?.response?.data?.detail
                    : "cannot delete folder"
                );
                console.log(error, "[+] error while deleting the directory");
            }
            await this.selectedConfig["file-manager"].init(
                null,
                folder.directory_type
            );
        },

        async onFileOpen(details) {
            this.$set(this.selectedConfig["file-manager"].props, "isLoading", true);
            try {
                const { data } = await axios.get(
                `/case-file-manager/get-case-entity-file-url/${details.file_id}`
                );
                window.open(data.url, "_blank");
            } catch (error) {
                console.log(error, "[+] failed to fetch file");
            }
            this.$set(this.selectedConfig["file-manager"].props, "isLoading", false);
        },
        async onSaveToOutput() {
            try {
                await axios.post(`/case-file-manager/output`, {
                case_id: this.case_id,
                report_name: this.pdfReportName,
                });
                this.$modal.hide("pdf-view-modal");
                await this.selectedConfig["file-manager"].init(null, "output");
                this.$toast.success("Report Saved to Output!");
            } catch (error) {
                this.$toast.error(
                error?.response?.data?.detail || "Failed to Save report to output!"
                );
            }
        },

        updateNewSequenceTemplates(value) {
            this.sequenceTemplates = value;
        },

        selectedGDCTab(selectedTab) {
            this.selectedIDTab = selectedTab;
        },
        gdcCheckData(data) {
            this.gdcUserData = data;
        },

        gdcSearchQuery(data) {
            this.searchQueryData = data;
        },
        clearSearchQuery() {
            this.searchQueryData = [];
        },
        getGdcFilteredData(data) {
            this.gdcBusinessData = data;
        },

        getGdcIdFilteredData(data) {
            this.gdcIdData = data;
        },
        filteredQueryData(item) {
            let name = (
                item?.query?.identity?.first_name +
                " " +
                item?.query?.identity?.middle_name +
                " " +
                item?.query?.identity?.last_name
            )
                .replace(/\s+/g, " ")
                .trim();
            let address = (
                item?.query?.address?.addressLine1 +
                " " +
                item?.query?.address?.addressLine2
            )
                .replace(/\s+/g, " ")
                .trim();
            let nameSection = this.CaseCheckFormPreviewData.sections.find(
                (el) => el.section_name == "Company Name"
            );
            let addressSection = this.CaseCheckFormPreviewData.sections.find(
                (el) => el.section_name == "Address"
            );
            let temp1 = 0;
            let temp2 = 0;
            addressSection.entities?.map((blockdata) => {
                let addressline1 = blockdata.entities.find(
                (el) => el.label == "Address Line 1"
                ).value;
                let addressline2 = blockdata.entities.find(
                (el) => el.label == "Address Line 2"
                ).value;
                let updatedAddressLine1 = addressline1 == null ? "" : addressline1;
                let updatedAddressLine2 = addressline2 == null ? "" : addressline2;
                let fullAddress = (updatedAddressLine1 + " " + updatedAddressLine2)
                .replace(/\s+/g, " ")
                .trim();
                if (fullAddress == address) {
                temp1 += 1;
                }
            });
            let companyName = nameSection.entities?.[0]?.entities.find(
                (el) => el.label == "Full Company Name"
            ).value;
            if (companyName == name) {
                temp2 += 1;
            }
            // this.gdcBusinessData['Address']?.map(el =>{
            //     if((el.addressLine1+' '+el.addressLine2).replace(/\s+/g, ' ').trim() == address)
            //         temp1 += 1;
            // })
            // this.gdcBusinessData['Company Name']?.map(el =>{
            //     if((el.full_company_name).replace(/\s+/g, ' ').trim() == name)
            //         temp2 += 1;
            // })
            if (temp1 == temp2 && temp1 != 0 && temp2 != 0) return true;
            else return false;
        },
        getOutputScreen(item) {
            this.showOutputScreen = true;
            this.currentOutputData = item;
        },
        getApiUrl(method) {
            return this.apiUrlMap.get(method);
        },
        async handleEddSearch() {
            try {
                this.eddSearchResultLoading = true;
                const case_id = this.$route.query.caseid;
                const response = await axios.post(
                `integrations/edd/${case_id}`,
                this.eddType
                );
                if (response?.status) {
                this.eddSearchResult = response?.data;
                this.eddResearchLink = response?.data?.ext_form_link;
                this.showEddSearchResult = true;
                this.eddDropdown = false;
                }
                this.eddSearchResultLoading = false;
            } catch (err) {
                this.eddSearchResultLoading = false;
                console.log("err", err);
                this.$toast.error(
                err?.response?.data?.detail[0]?.msg || "Something went wrong"
                );
            }
        },
        resetEdd() {
            this.eddType = null;
            this.showEddSearchResult = false;
        },
        handelEDDdResearchLink() {
            if (this.eddResearchLink) {
                window.open(this.eddResearchLink, "_blank");
            } else {
                this.$toast.error("Research link not found.");
            }
        },

        async getEddProductList() {
            try {
                this.eddProductListLoading = true;
                const case_id = this.$route.query.caseid;
                const response = await axios.get(
                `integrations/edd/${case_id}/products`
                );
                this.eddProductList = response?.data;
                this.eddProductListLoading = false;
            } catch (err) {
                this.eddProductListLoading = false;
                this.$toast.error(
                err?.response?.data?.detail | "Something went wrong."
                );
                console.log("err", err);
            }
        },

        async fetchEddResult() {
            console.log("sss")
            try {
                this.eddResultLoading = true;
                const case_id = this.$route.query.caseid;
                const response = await axios.get(`integrations/edd/${case_id}`);
                if (
                response?.status &&
                !isEmpty(response?.data) &&
                response?.data !== null &&
                response?.data !== "null"
                ) {
                this.existingEddSearchResult = response?.data;
                this.eddSearchResult = JSON.parse(
                    response?.data?.immediate_resp_success_json
                );
                this.fieldData1 = response?.data.entities[0];
                this.eddResearchLink = response?.data?.ext_form_link;
                this.eddDropdown = false;
                this.showEddSearchResult = true;
                this.isShowEDD = true;
                console.log("this.showCandidateForm", this.showCandidateForm);
                console.log("this.showSubTypeCard", this.showSubTypeCard);
                if(this.showCandidateForm || this.showSubTypeCard) {
                    this.showCandidateForm  = false;
                    this.showSubTypeCard = false;
                }
                } else {
                this.eddDropdown = true;
                this.showEddSearchResult = false;
                }
                this.eddResultLoading = false;
            } catch (err) {
                this.eddResultLoading = false;
                this.$toast.error(
                err?.response?.data?.detail || "Something went wrong."
                );
                console.log("err", err);
            }
        },

        handleCheckPreviewFormDataClose() {
            this.showCandidateForm = false;
            this.showSubTypeCard = false;
            this.fetchEddResult();
            this.isshowactivity = false;
            this.showCaseOfEDD= false;
            if(!this.isShowEDD && !this.showEddSearchResult && this.eddResultLoading)
            {
                this.isShowEDD = true
            }
            EventBus.$emit("unSelectEddInfoIcon", false);
        },

        async fetchFieldValueLog(field_fe_id, params = {}) {
            const url = `case/form/entity-form-field-value-logs/${this.getEntityId}/field/${field_fe_id}`;
            params = new URLSearchParams(params);
            return await axios(url, { params });
        },

        parseEntityCollectionData(payload) {
            const { id, forms, email, email_sequence_id } = payload;
            return {
                id,
                form: forms?.[0]?.form_id,
                user: email,
                sequence: email_sequence_id,
                role: forms?.[0]?.role,
            };
        },
        toggledCustomizedForms() {
            this.isCustomizedFormsOpened = !this.isCustomizedFormsOpened;
        },
        getEntityTypeName(entity) {
            if (!this.caseEntitiesData) return null;
            const { case_entity_type_info } = this.caseEntitiesData;
            return case_entity_type_info[entity.entity_type_id].name;
        },
    },
    computed: {
        ...mapGetters([
            "getRefChecks",
            "getUser",
            "getCaseNotificationCount",
            "getTenantId",
            "getCaseData",
            "getSelectedEntity",
        ]),
        caseEntity() {
            if (!this.caseEntitiesData) return null;
            const { case_entities } = this.caseEntitiesData;
            const caseEntity = case_entities?.find((entity) => {
                const entityType = this.getEntityTypeName(entity);
                return entityType === "Case";
            })
            if (!caseEntity) return null;
            return {
                id: caseEntity.id,
                name: caseEntity.entity_name,
                entity_type: this.getEntityTypeName(caseEntity),
            }
        },
        mainEntities() {
            if (!this.caseEntitiesData) return [];
            const { case_entities } = this.caseEntitiesData;
            return case_entities
                .filter((entity) => {
                    const entityType = this.getEntityTypeName(entity);
                    return entityType !== "Case";
                }).map((entity) => ({
                    entity: entity.entity_name,
                    id: entity.id,
                    entity_type: this.getEntityTypeName(entity),
                    relations: []
                }));
        },
        isCheckTool() {
            const checkedTools = ["lexis-bridger-tool", "company-name", "search-engine-tool"];
            if (checkedTools.includes(this.$route.query?.check_name)) {
                return true
            }
            else {
                return false
            }
        },
        currentCheckName() {
            return this.$route.query?.check_name
        },

        isInputFormEnabled() {
            return checkPermission("case.read_write_input");
        },
        isChecksFormDisabled() {
            return !checkPermission("case.read_write_check_screen");
        },
        getTabsList() {
            let permissions_map = {
                1: "read_input",
                3: "read_output",
            };
            //     1: "READ_INPUT",
            //     2: "READ_OUTPUT",
            // }
            // {
            //     id: 1,
            //     label: "Input",
            // },
            // {
            //     id: 2,
            //     label: "Checks",
            // },
            // {
            //     id: 3,
            //     label: "Output",
            // },
            return this.tabs.list.filter((tab) => {
                if (tab.id === 2) return true;
                else return checkPermission(`case.${permissions_map[tab.id]}`);
            });
        },

        selectedCaseFiles() {
            return this.attachments;
        },

        integratedCheckSubType() {
            let integratedCheckMappings = {
                "49c99c03-d07b-4561-9a4c-a887e23bfa28": "hooyu",
                "ef395d91-a982-45a2-a308-b123b23771a5": "transunion",
                "7c6e45d8-e5ff-4f89-b950-fc9448d09878": "dbs",
            };
            return integratedCheckMappings[this.activeCheckSubtype];
        },

        referenceChecks() {
            return this.getRefChecks;
        },
        currentActiveClient() {
            if (this.activeClient && this.clientsList.length)
                return this.clientsList.find(
                (client) => client.id === this.activeClient
                );
            else return null;
        },

        getClientId() {
            return this.getCaseData.client_id;
        },

        getPackageId() {
            return this.getCaseData.client_package_id;
        },

        btns() {
            return [
                {
                svg: "close-icon",
                key: "in-progress",
                name: "In Progress",
                },

                {
                svg: "del-icon",
                key: "on-hold",
                name: "On Hold",
                },
            ];
        },
        cardActions() {
            return {
                resetReferee() {
                this.selectedReferee = null;
                },
                selectCompany: (cardData) => {
                this.selectedReferee = cardData;
                this.$modal.show("referee-company-modal");
                },
                selectReferee: (cardData) => {
                this.selectedReferee = cardData;
                this.$modal.show("referee-modal");
                },
                newEmail: (cardData, type) => {
                const values = {
                    external_id: cardData.id,
                    external_id_type: type,
                    tag: type,
                    email_to:
                    cardData && cardData.refree
                        ? [
                            {
                            email: cardData.refree.email, // TODO finish when API is ready,
                            type: "Referee",
                            },
                        ]
                        : [],
                };
                this.resetEmailForm(values);
                this.showCaseOfEDD = true;
                this.activeIcon = "mail";
                this.$modal.show("mail");
                },
                showActivities: async (cardData, type) => {
                await this.selectedConfig.history.init(cardData.id, type);
                this.isCollapsed = true;
                this.selectedReferee = cardData;
                this.show_referee_form = false;
                this.showCandidateForm = false;
                this.showSubTypeCard = false;
                this.isShowEDD = false;
                this.showEddSearchResult= false;
                this.isshowactivity = true;
                this.external_id_type = type;
                this.showCaseOfEDD = false;
                },
                newNote: (cardData, type) => {
                // TODO use params
                this.handleResetCaseNotes();

                this.notes.external_id = cardData.id;
                this.notes.external_id_type = type || "referee";
                this.showCaseOfEDD = false;
                this.activeIcon = "notes";
                this.$modal.show("notes");
                },
                logCall: (cardData, type) => {
                // TODO use params
                this.activeIcon = "tel";
                this.activeCallLog = { ...cardData, type };
                this.selectedConfig.tel.init(cardData.id);
                this.showCaseOfEDD = false;
                },
                task: (cardData, type) => {
                // TODO use params
                this.activeTaskData = { ...cardData, type };
                this.activeIcon = "task";
                this.selectedConfig.task.init();
                this.showCaseOfEDD = false;
                },
                "file-manager": async (cardData, type) => {
                await this.selectedConfig["file-manager"].init(
                    cardData.id,
                    "input",
                    type
                );
                this.isCollapsed = true;
                this.selectedReferee = cardData;
                this.show_referee_form = false;
                this.showCandidateForm = false;
                this.showSubTypeCard = false;
                this.isShowEDD = false;
                this.showEddSearchResult= false;
                this.external_id_type = type;
                this.isshowactivity = true;
                this.showCaseOfEDD = false;
                },
                eddInfo: (cardData, type) => {
                this.show_referee_form = false;
                this.selectedReferee = '';
                this.showCandidateForm = true;
                this.showSubTypeCard = true;
                this.isShowEDD = false;
                this.showEddSearchResult= false;
                this.showCloseIconInCaseOfEDD=true;
                this.showCaseOfEDD=true;
                this.isshowactivity = true;
                this.isCollapsed = false;
                this.activeType = type;
                },
            };
        },
        computedUserList() {
            let list = [];
            let form_user_list = this.form_user_list;
            form_user_list.filter((item) => {
                let i = list.findIndex((x) => x.user_name == item.user_name);
                if (i <= -1) {
                list.push(item);
                }
                return null;
            });
            return list;
        },
        computedFormList() {
            let list = this.form_user_list;
            if (this.selected_form_user) {
                list = list.filter(
                (el) => el.user_name === this.selected_form_user.user_name
                );
            } else return [];
            return list;
        },

        formListColors() {
            return {
                text: "#282828",
                bg: "white",
                svg: "#282828",
                border: "#bcbcbc",
            };
        },
        formListConfig() {
            return { label: "form_name", trackBy: "id" };
        },

        hasEmailErrors() {
            const {
                task,
                time,
                date,
                task_assignee_id,
                notes,
                isClicked,
            } = this.emailActions;
            return {
                date: isClicked && task && !date,
                time: isClicked && task && !time,
                task_assignee_id: isClicked && task && !task_assignee_id,
                notes: isClicked && task && !notes,
            };
        },
        refereeFormSaveDisabled() {
            return !this.currentRefereeForm.some((el) => el.ref_action);
        },
        hideScrollbar() {
            return this.selectedConfig[this.activeIcon]?.props?.isLoading;
        },
        getEntityId() {
            return this.$route.query.entityId;
        },
        entityTypeToIcon() {
            return {
                Person: PersonIcon,
                Company: CompanyIcon,
            };
        },
        apiUrlMap() {
            return new Map([
                ["fetchFormPreview", `/case/form/${this.case_id}/entity/${this.getEntityId}`],
                ["saveSection", `/case/form/${this.case_id}/entity/${this.getEntityId}`],
                ["submitForm", `/case/form/${this.case_id}/entity/${this.getEntityId}/submit`],
                ["removeBlock", `/case/form/${this.case_id}/entity/${this.getEntityId}/multi-block`],
                ["removeField", `/case/form/${this.case_id}/entity/${this.getEntityId}/multi-field`],
                ["setCustomizedFormData", "email-sequence/template-sequence-linearlist"],
                ["setCustomizedFormEntityData", `case/${this.getEntityId}/email`],
                ["fetchDataCollections", `email-sequence/${this.case_id}/data-collection`],
                ["fetchAdditionalForms", `/case/${this.case_id}/${this.getEntityId}/forms`],
            ]);
        },

        customizedFormModel: {
            get() {
                return this.currentEntityCollectInfo?.map(this.parseEntityCollectionData);
            },
            async set(val) {
                try {
                const { data: changedValues, callback } = val;

                await this.updateCustomizedForms(changedValues);
                // reset original values:
                changedValues.forEach((el) => {
                    const sourceData = this.currentEntityCollectInfo?.find((sourceItem) => sourceItem.id === el.id);
                    if (!sourceData) return;
                    // currently, we can update only role configured for the assigned form;
                    // to update all properies the model values should be parsed to the original structure
                    const sourceForm = sourceData.forms.find((sourceForm) => sourceForm.form_id === el.form);
                    if (!sourceForm) return;
                    Object.assign(sourceForm, { role: el.role });
                })
                // reset 'updated' state:
                callback?.();
                } catch (error) {
                console.log('Failed to update customized forms', error);
                }
            }
        },

        currentEntity() {
            return this.caseEntitiesData?.case_entities?.find(entity => entity?.id === this.getEntityId);
        },

        showToggleCustomizedFormsButton() {
            return this.selectedCustomizedForm?.id === this.selected_dbs_form?.id;
        },

        showClearFiltersButton() {
            return this.selectedCustomizedForm?.id !== this.selected_dbs_form?.id;
        },

        selectedCustomizedForm() {
            return this.additionalForms?.find(form => form.form_name === "Customized form");
        },

        backToEddInitialStage() {
            console.log("back");
        },
        handleEddType(value) {
            console.log("evt", value);
        },
    },
};
